const state = {
    permissionsDialog: false,
    selectedPermissionIds: [],
    viewsDialog: false,
    selectedViewIds: [],
    scDialog: false,
    selectedSCIds: [],
    amenitiesDialog: false,
    selectedAmenityIds: [],
    rlDialog: false,
    selectedRLIds: [],
    lrDialog: false,
    selectedLRIds: [],
};

export default {
    state
};
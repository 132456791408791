import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const LOCATIONREMARKS_LIST = "locationremarks_list";
export const CREATE_LOCATIONREMARKS = "create_locationremarks";
export const UPDATE_LOCATIONREMARKS = "update_locationremarks";
export const DELETE_LOCATIONREMARKS = "delete_locationremarks";
export const GET_LOCATIONREMARKS_DETAILS = "get_locationremarks_details";
export const LOCATIONREMARKS_SIMPLE_LIST = "locationremarks_simple_details";
export const CLEAR_LOCATIONREMARKS_ERRORS = "clearLocationRemarksErrors";

// mutation types
export const SET_LOCATIONREMARKS_ERROR = "setLocationRemarksErrors";
export const REMOVE_LOCATIONREMARKS_ERRORS = "removeLocationRemarksErrors";

const state = {
    errors: null
};
const actions = {
    [LOCATIONREMARKS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/locationremarks?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LOCATIONREMARKS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LOCATIONREMARKS_ERROR, response.errors);
                });
        });
    },
    [CREATE_LOCATIONREMARKS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createlocationremark", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LOCATIONREMARKS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LOCATIONREMARKS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_LOCATIONREMARKS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatelocationremark", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LOCATIONREMARKS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LOCATIONREMARKS_ERROR, response.errors);
                });
        });
    },
    [DELETE_LOCATIONREMARKS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletelocationremark", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LOCATIONREMARKS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LOCATIONREMARKS_ERROR, response.errors);
                });
        });
    },
    [GET_LOCATIONREMARKS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getlocationremarkdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LOCATIONREMARKS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LOCATIONREMARKS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_LOCATIONREMARKS_ERRORS](context) {
        context.commit(REMOVE_LOCATIONREMARKS_ERRORS);
    },
    [LOCATIONREMARKS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/locationremarkssimple?type=" + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_LOCATIONREMARKS_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_LOCATIONREMARKS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_LOCATIONREMARKS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

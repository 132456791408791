import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const COUNTRIES_LIST = "countries_list";
export const CREATE_COUNTRIES = "create_countries";
export const UPDATE_COUNTRIES = "update_countries";
export const DELETE_COUNTRIES = "delete_countries";
export const GET_COUNTRIES_DETAILS = "get_countries_details";
export const COUNTRIES_SIMPLE_LIST = "countries_simple_details";
export const CLEAR_COUNTRIES_ERRORS = "clearCountriesErrors";

// mutation types
export const SET_COUNTRIES_ERROR = "setCountriesError";
export const REMOVE_COUNTRIES_ERRORS = "removeCountriesErrors";

const state = {
    errors: null
};
const actions = {
    [COUNTRIES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/countries?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_COUNTRIES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_COUNTRIES_ERROR, response.errors);
                });
        });
    },
    [CREATE_COUNTRIES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createcountry", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_COUNTRIES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_COUNTRIES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_COUNTRIES](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatecountry", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_COUNTRIES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_COUNTRIES_ERROR, response.errors);
                });
        });
    },
    [DELETE_COUNTRIES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletecountry", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_COUNTRIES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_COUNTRIES_ERROR, response.errors);
                });
        });
    },
    [GET_COUNTRIES_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getcountrydetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_COUNTRIES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_COUNTRIES_ERROR, response.errors);
                });
        });
    },
    [CLEAR_COUNTRIES_ERRORS](context) {
        context.commit(REMOVE_COUNTRIES_ERRORS);
    },
    [COUNTRIES_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/countriessimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
};

const mutations = {
    [SET_COUNTRIES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_COUNTRIES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const LISTINGS_LIST = "listings_list";
export const PROPERTIES_LIST = "PROPERTIES_LIST";
export const ASSIGNED_LISTINGS_LIST = "assigned_listings_list";
export const SHARED_LISTINGS_LIST = "shared_listings_list";
export const LISTINGS_SIMPLE_LIST = "listings_simple_list";
export const LISTINGS_SIMPLE_BY_IDS_LIST = "listings_simple_by_ids_list";
export const SHARE_LISTING = "share_listings";
export const CREATE_LISTINGS = "create_listings";
export const UPDATE_LISTINGS = "update_listings";
export const UPDATE_PROPERTY_LISTINGS = "update_property_listings";
export const DELETE_LISTINGS = "delete_listings";
export const DUPLICATE_LISTING = "duplicate_listing";
export const GET_LISTINGS_DETAILS = "get_listings_details";
export const UPDATE_LISTINGS_STATUS = "update_listings_status";
export const LISTINGS_DASHBOARD_STATS = "LISTINGS_DASHBOARD_STATS";
export const LISTINGS_DASHBOARD_ALL_COUNT = "LISTINGS_DASHBOARD_ALL_COUNT";
export const CLEAR_LISTINGS_ERRORS = "clearListingsErrors";

// mutation types
export const SET_LISTINGS_ERROR = "setListingsError";
export const REMOVE_LISTINGS_ERRORS = "removeListingsErrors";


const state = {
    errors: null,
    uploadedListingFees: [],
    uploadedListingFeatures: [],
    latitude: '',
    longitude: '',
    yearlyServiceCharges: '',
    monthlyCoolingCharges: ''
};
const actions = {
    [LISTINGS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/listings?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LISTINGS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [PROPERTIES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/properties?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LISTINGS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [LISTINGS_DASHBOARD_STATS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/listingsdashboardstats?fromDateSearch=" + payload.fromDateSearch + '&toDateSearch=' + payload.toDateSearch + '&viewFull=' + payload.viewFull)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LISTINGS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [LISTINGS_DASHBOARD_ALL_COUNT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/listingsdashboardallcount?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LISTINGS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [SHARED_LISTINGS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/sharedlistings?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LISTINGS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [ASSIGNED_LISTINGS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/assignedlistings?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LISTINGS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [SHARE_LISTING](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/sharelistings", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LISTINGS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [CREATE_LISTINGS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createlisting", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LISTINGS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_LISTINGS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatelisting", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LISTINGS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PROPERTY_LISTINGS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatepropertylisting", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LISTINGS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [DUPLICATE_LISTING](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/duplicatelisting", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LISTINGS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [DELETE_LISTINGS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletelisting", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LISTINGS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [GET_LISTINGS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getlistingdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LISTINGS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_LISTINGS_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatelistingstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LISTINGS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LISTINGS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_LISTINGS_ERRORS](context) {
        context.commit(REMOVE_LISTINGS_ERRORS);
    },
    [LISTINGS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/listingssimple?type=" + payload.type + '&ref_id=' + payload.refId)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_LISTINGS_ERROR, response.errors);
            });
        });
    },
    [LISTINGS_SIMPLE_BY_IDS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/listingssimplebyids?ids=" + payload.ids)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_LISTINGS_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_LISTINGS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_LISTINGS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

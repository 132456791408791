import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const LABELS_LIST = "labels_list";
export const CREATE_LABELS = "create_labels";
export const UPDATE_LABELS = "update_labels";
export const DELETE_LABELS = "delete_labels";
export const GET_LABELS_DETAILS = "get_labels_details";
export const LABELS_SIMPLE_LIST = "labels_simple_details";
export const CLEAR_LABELS_ERRORS = "clearLabelsErrors";

// mutation types
export const SET_LABELS_ERROR = "setLabelsError";
export const REMOVE_LABELS_ERRORS = "removeLabelsErrors";

const state = {
    errors: null
};
const actions = {
    [LABELS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/labels?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LABELS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LABELS_ERROR, response.errors);
                });
        });
    },
    [CREATE_LABELS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createlabel", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LABELS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LABELS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_LABELS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatelabel", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LABELS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LABELS_ERROR, response.errors);
                });
        });
    },
    [DELETE_LABELS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletelabel", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LABELS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LABELS_ERROR, response.errors);
                });
        });
    },
    [GET_LABELS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getlabeldetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LABELS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LABELS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_LABELS_ERRORS](context) {
        context.commit(REMOVE_LABELS_ERRORS);
    },
    [LABELS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/labelssimple?type=" + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_LABELS_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_LABELS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_LABELS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

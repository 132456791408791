import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import selections from "./selections.module";
import developers from "./developers.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import snackbar from "./snackbar.module";
import confirmation from "./confirmation.module";
import uploads from "./uploads.module";
import dashboard from "./dashboard.module";
import reports from "./reports.module";
import roles from "./roles.module";
import users from "./users.module";
import permissions from "./permissions.module";
import countries from "./countries.module";
import cities from "./cities.module";
import pages from "./pages.module";
import menus from "./menus.module";
import banners from "./banners.module";
import posts from "./posts.module";
import developments from "./developments.module";
import sources from "./sources.module";
import commons from "./commons.module";
import completion_statuses from "./completion_statuses.module";
import views from "./views.module";
import brands from "./brands.module";
import labels from "./labels.module";
import salutations from "./salutations.module";
import cards from "./cards.module";
import projects from "./projects.module";
import purposes from "./purposes.module";
import publishing_statuses from "./publishing_statuses.module";
import vendors from "./vendors.module";
import teams from "./teams.module";
import inquiry_statuses from "./inquiry_statuses.module";
import contacts from "./contacts.module";
import lifecycles from "./lifecycles.module";
import developmenttypes from "./developmenttypes.module";
import roadlocations from "./road_locations.module";
import specialclassifications from "./specialclassifications.module";
import occupations from "./occupations.module";
import domains from "./domains.module";
import contact_roles from "./contact_roles.module";
import amenities from "./amenities.module";
import locationremarks from "./locationremarks.module";
import commenttitles from "./commenttitles.module";
import projectunitdetails from "./projectunitdetails.module";
import projectunitdetailspm from "./projectunitdetailspm.module";
import developmentunitdetails from "./developmentunitdetails.module";
import projectunittypes from "./projectunittypes.module";
import listings from "./listings.module";
import notifications from "./notifications.module";
import leads from "./leads.module";
import merge from "./merge.module";
import frequencies from "./frequencies.module";
import projecttypicalfloorplans from "./projecttypicalfloorplans.module";
import projectpaymentplans from "./projectpaymentplans.module";

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        appUrl: '',
        qsDialog: false,
        qsQuery: '',
        adsDateText: 'Updated As Of 20th January 2024',
        // nCount: 0
    },
    modules: {
        auth,
        selections,
        htmlClass,
        config,
        uploads,
        developers,
        breadcrumbs,
        snackbar,
        confirmation,
        roles,
        pages,
        menus,
        posts,
        banners,
        dashboard,
        reports,
        permissions,
        users,
        countries,
        cities,
        developments,
        notifications,
        sources,
        completion_statuses,
        views,
        brands,
        labels,
        salutations,
        cards,
        projects,
        purposes,
        publishing_statuses,
        vendors,
        teams,
        inquiry_statuses,
        lifecycles,
        roadlocations,
        developmenttypes,
        specialclassifications,
        amenities,
        occupations,
        domains,
        contact_roles,
        commenttitles,
        locationremarks,
        projectunitdetails,
        projectunitdetailspm,
        developmentunitdetails,
        projectunittypes,
        projecttypicalfloorplans,
        projectpaymentplans,
        listings,
        leads,
        merge,
        frequencies,
        commons,
        contacts
    }
});

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const COMPLETIONSTATESES_LIST = "temp_list";
export const CREATE_COMPLETIONSTATUSES = "create_completion_statuses";
export const UPDATE_COMPLETIONSTATUSES = "update_completion_statuses";
export const DELETE_COMPLETIONSTATUSES = "delete_completion_statuses";
export const GET_COMPLETIONSTATUSES_DETAILS = "get_completion_statuses_details";
export const COMPLETIONSTATUSES_SIMPLE_LIST = "completion_statuses_simple_details";
export const CLEAR_COMPLETIONSTATUSES_ERRORS = "clearCompletionStatusesErrors";

// mutation types
export const SET_COMPLETIONSTATUSES_ERROR = "setCompletionStatusesError";
export const REMOVE_COMPLETIONSTATUSES_ERRORS = "removeCompletionStatusesErrors";

const state = {
    errors: null
};
const actions = {
    [COMPLETIONSTATESES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/completionstatuses?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_COMPLETIONSTATUSES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_COMPLETIONSTATUSES_ERROR, response.errors);
                });
        });
    },
    [CREATE_COMPLETIONSTATUSES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createcompletionstatuses", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_COMPLETIONSTATUSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_COMPLETIONSTATUSES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_COMPLETIONSTATUSES](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatecompletionstatuses", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_COMPLETIONSTATUSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_COMPLETIONSTATUSES_ERROR, response.errors);
                });
        });
    },
    [DELETE_COMPLETIONSTATUSES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletecompletionstatuses", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_COMPLETIONSTATUSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_COMPLETIONSTATUSES_ERROR, response.errors);
                });
        });
    },
    [GET_COMPLETIONSTATUSES_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getcompletionstatusesdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_COMPLETIONSTATUSES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_COMPLETIONSTATUSES_ERROR, response.errors);
                });
        });
    },
    [CLEAR_COMPLETIONSTATUSES_ERRORS](context) {
        context.commit(REMOVE_COMPLETIONSTATUSES_ERRORS);
    },
    [COMPLETIONSTATUSES_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/completionstatusessimple?type=" + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_COMPLETIONSTATUSES_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_COMPLETIONSTATUSES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_COMPLETIONSTATUSES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const CONTACTROLES_LIST = "contactroles_list";
export const CREATE_CONTACTROLES = "create_contactroles";
export const UPDATE_CONTACTROLES = "update_contactroles";
export const DELETE_CONTACTROLES = "delete_contactroles";
export const GET_CONTACTROLES_DETAILS = "get_contactroles_details";
export const CONTACTROLES_SIMPLE_LIST = "contactroles_simple_details";
export const CLEAR_CONTACTROLES_ERRORS = "clearContactRolesErrors";

// mutation types
export const SET_CONTACTROLES_ERROR = "setContactRolesErrors";
export const REMOVE_CONTACTROLES_ERRORS = "removeContactRolesErrors";

const state = {
    errors: null
};
const actions = {
    [CONTACTROLES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/contactroles?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CONTACTROLES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_CONTACTROLES_ERROR, response.errors);
                });
        });
    },
    [CREATE_CONTACTROLES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createcontactrole", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACTROLES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CONTACTROLES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_CONTACTROLES](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatecontactrole", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACTROLES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CONTACTROLES_ERROR, response.errors);
                });
        });
    },
    [DELETE_CONTACTROLES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletecontactrole", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACTROLES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CONTACTROLES_ERROR, response.errors);
                });
        });
    },
    [GET_CONTACTROLES_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getcontactroledetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CONTACTROLES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_CONTACTROLES_ERROR, response.errors);
                });
        });
    },
    [CLEAR_CONTACTROLES_ERRORS](context) {
        context.commit(REMOVE_CONTACTROLES_ERRORS);
    },
    [CONTACTROLES_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/contactrolessimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
};

const mutations = {
    [SET_CONTACTROLES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_CONTACTROLES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

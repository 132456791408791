import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const PROJECTS_LIST = "projects_list";
export const PROJECTS_DATA_LIST = "projects_data_list";
export const PROJECT_UNIT_TYPES_LIST = "project_unit_types_list";
export const PROJECTS_SIMPLE_LIST = "projects_simple_list";
export const PROJECTS_SIMPLE_BY_IDS_LIST = "PROJECTS_SIMPLE_BY_IDS_LIST";
export const CREATE_PROJECTS = "create_projects";
export const UPDATE_PROJECTS = "update_projects";
export const DELETE_PROJECTS = "delete_projects";
export const SYNC_PROJECT_TRANSACTIONS = "SYNC_PROJECT_TRANSACTIONS";
export const DUPLICATE_PROJECT = "duplicate_project";
export const GET_PROJECTS_DETAILS = "get_projects_details";
export const UPDATE_PROJECTS_STATUS = "update_projects_status";
export const MOVE_PROJECTS_TO_DD = "MOVE_PROJECTS_TO_DD";
export const CLEAR_PROJECTS_ERRORS = "clearProjectsErrors";

// mutation types
export const SET_PROJECTS_ERROR = "setProjectsError";
export const REMOVE_PROJECTS_ERRORS = "removeProjectsErrors";


const state = {
    errors: null,
    uploadedProjectUnitDetails: [],
    uploadedProjectBankDetails: [],
    latitude: '',
    longitude: ''
};
const actions = {
    [PROJECTS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/projects?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [PROJECTS_DATA_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/projectssimpledata?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [CREATE_PROJECTS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createproject", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PROJECTS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateproject", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [DELETE_PROJECTS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteproject", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [SYNC_PROJECT_TRANSACTIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/syncprojecttransactions", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [DUPLICATE_PROJECT](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/duplicateproject", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [GET_PROJECTS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getprojectdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PROJECTS_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateprojectstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [MOVE_PROJECTS_TO_DD](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/moveprojectstodevelopmentdetails", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_PROJECTS_ERRORS](context) {
        context.commit(REMOVE_PROJECTS_ERRORS);
    },
    [PROJECTS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/projectssimple?q=" + payload.q + '&type=' + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_PROJECTS_ERROR, response.errors);
            });
        });
    },
    [PROJECTS_SIMPLE_BY_IDS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/projectssimplebyids?ids=" + payload.ids)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_PROJECTS_ERROR, response.errors);
            });
        });
    },
    [PROJECT_UNIT_TYPES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/projectunittypes?project_id=" + payload.project_id + '&type=' + payload.type + '&' + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTS_ERROR, response.errors);
                });
        });
    },
};

const mutations = {
    [SET_PROJECTS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_PROJECTS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const SPECIALCLASSIFICATIONS_LIST = "specialclassifications_list";
export const CREATE_SPECIALCLASSIFICATIONS = "create_specialclassifications";
export const UPDATE_SPECIALCLASSIFICATIONS = "update_specialclassifications";
export const DELETE_SPECIALCLASSIFICATIONS = "delete_specialclassifications";
export const GET_SPECIALCLASSIFICATIONS_DETAILS = "get_specialclassifications_details";
export const SPECIALCLASSIFICATIONS_SIMPLE_LIST = "specialclassifications_simple_details";
export const CLEAR_SPECIALCLASSIFICATIONS_ERRORS = "clearSpecialClassificationsErrors";

// mutation types
export const SET_SPECIALCLASSIFICATIONS_ERROR = "setSpecialClassificationsErrors";
export const REMOVE_SPECIALCLASSIFICATIONS_ERRORS = "removeSpecialClassificationsErrors";

const state = {
    errors: null
};
const actions = {
    [SPECIALCLASSIFICATIONS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/specialclassifications?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, response.errors);
                });
        });
    },
    [CREATE_SPECIALCLASSIFICATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createspecialclassification", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_SPECIALCLASSIFICATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatespecialclassification", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, response.errors);
                });
        });
    },
    [DELETE_SPECIALCLASSIFICATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletespecialclassification", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, response.errors);
                });
        });
    },
    [GET_SPECIALCLASSIFICATIONS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getspecialclassificationdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_SPECIALCLASSIFICATIONS_ERRORS](context) {
        context.commit(REMOVE_SPECIALCLASSIFICATIONS_ERRORS);
    },
    [SPECIALCLASSIFICATIONS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/specialclassificationssimple?type=" + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_SPECIALCLASSIFICATIONS_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_SPECIALCLASSIFICATIONS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_SPECIALCLASSIFICATIONS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const CITYS_LIST = "citys_list";
export const CREATE_CITYS = "create_citys";
export const UPDATE_CITYS = "update_citys";
export const DELETE_CITYS = "delete_citys";
export const GET_CITYS_DETAILS = "get_citys_details";
export const CITYS_SIMPLE_LIST = "citys_simple_list";
export const CLEAR_CITYS_ERRORS = "clearCitysErrors";

// mutation types
export const SET_CITYS_ERROR = "setCitysError";
export const REMOVE_CITYS_ERRORS = "removeCitysErrors";

const state = {
    errors: null
};

const actions = {
    [CITYS_LIST](context, payload) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.simpledatatable("api/cities?" + payload.q + payload.columns.join(''))
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                            context.commit(SET_CITYS_ERROR, data.errors);
                        }
                    })
                    .catch(({response}) => {
                        context.commit(SET_CITYS_ERROR, response.errors);
                    });
            });
    },
    [CREATE_CITYS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createcity", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CITYS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CITYS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_CITYS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("/api/updatecity", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CITYS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CITYS_ERROR, response.errors);
                });
        });
    },
    [DELETE_CITYS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletecity", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CITYS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CITYS_ERROR, response.errors);
                });
        });
    },
    [GET_CITYS_DETAILS](context, id) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.get("api/getcitydetail", id)
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                            context.commit(SET_CITYS_ERROR, data.errors);
                        }
                    })
                    .catch(({response}) => {
                        context.commit(SET_CITYS_ERROR, response.errors);
                    });
            });
    },
    [CITYS_SIMPLE_LIST](context, payload) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.datatable("api/cityssimple?country_id=" + payload.country_id)
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        }
                    })
                    .catch(({response}) => {
                        context.commit(SET_CITYS_ERROR, response.errors);
                    });
            });
    },
    [CLEAR_CITYS_ERRORS](context) {
        context.commit(REMOVE_CITYS_ERRORS);
    }
};

const mutations = {
    [SET_CITYS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_CITYS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const DEVELOPMENTS_LIST = "developments_list";
export const DEVELOPMENTS_SIMPLE_LIST = "developments_simple_details";
export const DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST = "DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST";
export const DEVELOPMENTS_SIMPLE_BY_IDS_LIST = "DEVELOPMENTS_SIMPLE_BY_IDS_LIST";
export const DEVELOPMENTS_BY_TYPE = "developments_by_type";
export const DEVELOPMENTS_WITH_COUNT = "DEVELOPMENTS_WITH_COUNT";
export const CREATE_DEVELOPMENTS = "create_developments";
export const UPDATE_DEVELOPMENTS = "update_developments";
export const DELETE_DEVELOPMENTS = "delete_developments";
export const SYNC_DEVELOPMENT_TRANSACTIONS = "SYNC_DEVELOPMENT_TRANSACTIONS";
export const GET_DEVELOPMENTS_DETAILS = "get_developments_details";
export const GET_DEVELOPMENTS_TREE = "get_developments_tree";
export const UPDATE_DEVELOPMENTS_STATUS = "update_developments_status";
export const CLEAR_DEVELOPMENTS_ERRORS = "clearDevelopmentsErrors";

// mutation types
export const SET_DEVELOPMENTS_ERROR = "setDevelopmentsError";
export const REMOVE_DEVELOPMENTS_ERRORS = "removeDevelopmentsErrors";


const state = {
    errors: null,
    latitude: '',
    longitude: ''
};
const actions = {
    [DEVELOPMENTS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/developments?type=" + payload.type + "&" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DEVELOPMENTS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
                });
        });
    },
    [CREATE_DEVELOPMENTS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createdevelopment", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DEVELOPMENTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_DEVELOPMENTS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatedevelopment", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DEVELOPMENTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
                });
        });
    },
    [DELETE_DEVELOPMENTS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletedevelopment", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DEVELOPMENTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
                });
        });
    },
    [SYNC_DEVELOPMENT_TRANSACTIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/syncdevelopmenttransactions", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DEVELOPMENTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
                });
        });
    },
    [GET_DEVELOPMENTS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getdevelopmentdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DEVELOPMENTS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
                });
        });
    },
    [GET_DEVELOPMENTS_TREE](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getdevelopmenttree/" + payload.id + '?type=' + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DEVELOPMENTS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
                });
        });
    },
    [DEVELOPMENTS_WITH_COUNT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/developmentswithcount?type=" + payload.type + '&id=' + payload.id + '&get=' + payload.get + '&' + payload.query)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DEVELOPMENTS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_DEVELOPMENTS_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatedevelopmentstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DEVELOPMENTS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_DEVELOPMENTS_ERRORS](context) {
        context.commit(REMOVE_DEVELOPMENTS_ERRORS);
    },
    [DEVELOPMENTS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/developmentssimple?q=" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
            });
        });
    },
    [DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/developmentsandprojectssimple?q=" + payload.q + '&country_id=' + payload.countryId + '&city_id=' + payload.cityId + '&type=' + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
            });
        });
    },
    [DEVELOPMENTS_SIMPLE_BY_IDS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/developmentssimplebyids?ids=" + payload.ids)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
            });
        });
    },
    [DEVELOPMENTS_BY_TYPE](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/developmentsbytype?q=" + payload.q + '&type=' + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_DEVELOPMENTS_ERROR, response.errors);
            });
        });
    }
};

const mutations = {
    [SET_DEVELOPMENTS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_DEVELOPMENTS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const INQUIRYSTATUSES_LIST = "inquiry_statuses_list";
export const CREATE_INQUIRYSTATUSES = "create_inquiry_status";
export const UPDATE_INQUIRYSTATUSES = "update_inquiry_status";
export const DELETE_INQUIRYSTATUSES = "delete_inquiry_status";
export const GET_INQUIRYSTATUSES_DETAILS = "get_inquiry_status_details";
export const INQUIRYSTATUSES_SIMPLE_LIST = "inquiry_statuses_simple_details";
export const CLEAR_INQUIRYSTATUSES_ERRORS = "clearInquiryStatusesErrors";

// mutation types
export const SET_INQUIRYSTATUSES_ERROR = "setInquiryStatusesError";
export const REMOVE_INQUIRYSTATUSES_ERRORS = "removeInquiryStatusesErrors";

const state = {
    errors: null
};

const actions = {
    [INQUIRYSTATUSES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/inquirystatuses?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_INQUIRYSTATUSES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_INQUIRYSTATUSES_ERROR, response.errors);
                });
        });
    },
    [CREATE_INQUIRYSTATUSES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createinquirystatus", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_INQUIRYSTATUSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_INQUIRYSTATUSES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_INQUIRYSTATUSES](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("/api/updateinquirystatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_INQUIRYSTATUSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_INQUIRYSTATUSES_ERROR, response.errors);
                });
        });
    },
    [DELETE_INQUIRYSTATUSES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteinquirystatus", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_INQUIRYSTATUSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_INQUIRYSTATUSES_ERROR, response.errors);
                });
        });
    },
    [GET_INQUIRYSTATUSES_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getinquirystatusdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_INQUIRYSTATUSES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_INQUIRYSTATUSES_ERROR, response.errors);
                });
        });
    },
    [INQUIRYSTATUSES_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/inquirystatusessimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
    [CLEAR_INQUIRYSTATUSES_ERRORS](context) {
        context.commit(REMOVE_INQUIRYSTATUSES_ERRORS);
    }
};

const mutations = {
    [SET_INQUIRYSTATUSES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_INQUIRYSTATUSES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const PROJECTPAYMENTPLANS_LIST = "projectpaymentplans_list";
export const CREATE_PROJECTPAYMENTPLANS = "create_projectpaymentplans";
export const UPDATE_PROJECTPAYMENTPLANS = "update_projectpaymentplans";
export const DELETE_PROJECTPAYMENTPLANS = "delete_projectpaymentplans";
export const GET_PROJECTPAYMENTPLANS_DETAILS = "get_projectpaymentplans_details";
export const PROJECTPAYMENTPLANS_SIMPLE_LIST = "projectpaymentplans_simple_details";
export const UPDATE_PROJECTPAYMENTPLANS_STATUS = "UPDATE_PROJECTPAYMENTPLANS_STATUS";
export const CLEAR_PROJECTPAYMENTPLANS_ERRORS = "clearProjectPaymentPlansErrors";

// mutation types
export const SET_PROJECTPAYMENTPLANS_ERROR = "setProjectPaymentPlansErrors";
export const REMOVE_PROJECTPAYMENTPLANS_ERRORS = "removeProjectPaymentPlansErrors";

const state = {
    errors: null,
    projectPaymentPlanDistribution: [],
    projectPaymentPlanDistributionPP: [],
};
const actions = {
    [PROJECTPAYMENTPLANS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/projectpaymentplans?project_id=" + payload.project_id  + '&' + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTPAYMENTPLANS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, response.errors);
                });
        });
    },
    [CREATE_PROJECTPAYMENTPLANS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createprojectpaymentplan", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PROJECTPAYMENTPLANS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateprojectpaymentplan", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, response.errors);
                });
        });
    },
    [DELETE_PROJECTPAYMENTPLANS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteprojectpaymentplan", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, response.errors);
                });
        });
    },
    [GET_PROJECTPAYMENTPLANS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getprojectpaymentplandetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTPAYMENTPLANS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_PROJECTPAYMENTPLANS_ERRORS](context) {
        context.commit(REMOVE_PROJECTPAYMENTPLANS_ERRORS);
    },
    [PROJECTPAYMENTPLANS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/projectpaymentplanssimple?project_id=" + payload.project_id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_PROJECTPAYMENTPLANS_ERROR, response.errors);
            });
        });
    },
    [UPDATE_PROJECTPAYMENTPLANS_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateprojectpaymentplanstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTPAYMENTPLANS_ERROR, response.errors);
                });
        });
    },
};

const mutations = {
    [SET_PROJECTPAYMENTPLANS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_PROJECTPAYMENTPLANS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

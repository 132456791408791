import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";


// action types
export const CONTACTS_LIST = "contacts_list";
export const SHARED_CONTACTS_USERS_LIST = "SHARED_CONTACTS_USERS_LIST";
export const DELETE_SHARED_CONTACT_USER = "DELETE_SHARED_CONTACT_USER";
export const SHARED_CONTACTS_LIST = "shared_contacts_list";
export const GET_CONTACT_PROPERTIES = "GET_CONTACT_PROPERTIES";
export const CREATE_CONTACT = "create_contact";
export const UPDATE_CONTACT = "update_contact";
export const UPDATE_CONTACT_DETAILS = "UPDATE_CONTACT_DETAILS";
export const SHARE_CONTACTS = "share_contacts";
export const GET_COMMENT = "get_comment";
export const SAVE_COMMENT = "save_comment";
export const DELETE_COMMENT = "delete_comment";
export const GET_REMINDER = "GET_REMINDER";
export const SAVE_REMINDER = "SAVE_REMINDER";
export const VERIFY_NUMBERS = "VERIFY_NUMBERS";
export const VERIFY_EMAILS = "VERIFY_EMAILS";
export const SEND_SMS = "SEND_SMS";
export const SEND_EMAIL = "SEND_EMAIL";
export const DELETE_CONTACT = "delete_contact";
export const CONTACTS_SIMPLE_LIST = "contacts_simple_list";
export const GET_CONTACT_DETAILS = "get_contact_details";
export const GET_CONTACT_DETAILS_MAIN = "get_contact_details_main";
export const CLEAR_CONTACT_ERRORS = "clearContactErrors";

// mutation types
export const SET_CONTACT_ERRORS = "setContactError";
export const REMOVE_CONTACT_ERRORS = "removeContactErrors";

const state = {
    errors: null,
    uploadedContactDetails: {},
    uploadedContactBankDetails: [],
    uploadedContactCardDetails: [],
    uploadedContactComments: [],
};


const actions = {
    [CONTACTS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/contacts?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [SHARED_CONTACTS_USERS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/sharedcontactsusers?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [DELETE_SHARED_CONTACT_USER](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/deletesharedcontactuser", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [SHARED_CONTACTS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/sharedcontacts?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [GET_CONTACT_PROPERTIES](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/getcontactproperties?contact_id=" + payload.contact_id + '&' + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CONTACT_ERRORS, data.errors);
                    }
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [CREATE_CONTACT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/createcontact", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [UPDATE_CONTACT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.update("api/updatecontact", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [UPDATE_CONTACT_DETAILS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/updatecontactdetails", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [SHARE_CONTACTS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/sharecontacts", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [GET_COMMENT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getcomments/" + payload.contact_id + '?page=' + payload.page)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CONTACT_ERRORS, data.errors);
                    }
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [SAVE_COMMENT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/savecomment", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [DELETE_COMMENT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/deletecomment", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [GET_REMINDER](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getreminders/" + payload.contact_id + '?page=' + payload.page)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CONTACT_ERRORS, data.errors);
                    }
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [SAVE_REMINDER](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/savereminder", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [VERIFY_NUMBERS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/verifynumbers", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    // this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [VERIFY_EMAILS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/verifyemails", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    // this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [SEND_SMS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/sendsms", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    // this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [SEND_EMAIL](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/sendemail", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    // this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [DELETE_CONTACT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/deletecontact", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CONTACT_ERRORS, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [GET_CONTACT_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getcontactdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CONTACT_ERRORS, data.errors);
                    }
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [GET_CONTACT_DETAILS_MAIN](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getcontactdetailmain", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CONTACT_ERRORS, data.errors);
                    }
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_CONTACT_ERRORS, response.errors);
                });
        });
    },
    [CONTACTS_SIMPLE_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.datatable("api/contactssimple?role=" + payload.role + "&q=" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_CONTACT_ERRORS, response.errors);
            });
        });
    },
    [CLEAR_CONTACT_ERRORS](context) {
        context.commit(REMOVE_CONTACT_ERRORS);
    },
};

const mutations = {
    [SET_CONTACT_ERRORS](state, error) {
        state.errors = error;
    },
    [REMOVE_CONTACT_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

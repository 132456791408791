import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const OCCUPATIONS_LIST = "occupations_list";
export const CREATE_OCCUPATIONS = "create_occupations";
export const UPDATE_OCCUPATIONS = "update_occupations";
export const DELETE_OCCUPATIONS = "delete_occupations";
export const GET_OCCUPATIONS_DETAILS = "get_occupations_details";
export const OCCUPATIONS_SIMPLE_LIST = "occupations_simple_details";
export const CLEAR_OCCUPATIONS_ERRORS = "clearOccupationsErrors";

// mutation types
export const SET_OCCUPATIONS_ERROR = "setOccupationsErrors";
export const REMOVE_OCCUPATIONS_ERRORS = "removeOccupationsErrors";

const state = {
    errors: null
};
const actions = {
    [OCCUPATIONS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/occupations?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_OCCUPATIONS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_OCCUPATIONS_ERROR, response.errors);
                });
        });
    },
    [CREATE_OCCUPATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createoccupation", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_OCCUPATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_OCCUPATIONS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_OCCUPATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateoccupation", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_OCCUPATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_OCCUPATIONS_ERROR, response.errors);
                });
        });
    },
    [DELETE_OCCUPATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteoccupation", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_OCCUPATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_OCCUPATIONS_ERROR, response.errors);
                });
        });
    },
    [GET_OCCUPATIONS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getoccupationdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_OCCUPATIONS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_OCCUPATIONS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_OCCUPATIONS_ERRORS](context) {
        context.commit(REMOVE_OCCUPATIONS_ERRORS);
    },
    [OCCUPATIONS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/occupationssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
};

const mutations = {
    [SET_OCCUPATIONS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_OCCUPATIONS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

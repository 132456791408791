export default {
    data() {
        return {
            template1: '<table style="border-collapse:collapse" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff">\n' +
                '            <tbody>\n' +
                '            <tr><td style="border-collapse:collapse" align="center">\n' +
                '                <table style="border-collapse:collapse;table-layout:fixed;width:560px" width="560" cellspacing="0" cellpadding="0" border="0"><tbody>\n' +
                '                        <tr>\n' +
                '                            <td style="border-collapse:collapse" valign="top" align="center">\n' +
                '                                <img src="http://dubricks.com/assets/email-template/logo.png">\n' +
                '\n' +
                '                            </td>\n' +
                '                        </tr>\n' +
                '                        <tr>\n' +
                '                            <td style="border-collapse:collapse" height="100" bgcolor="#85c242" valign="top" align="middle">\n' +
                '                                <div style="padding:3%; color:#fff;font-family:\'Helvetica\',Arial,sans-serif;font-size:15pt;line-height:30px;text-align:center">\n' +
                '                                @NumberofProperties handpicked properties for you @ContactFullName\n' +
                '                                </div>\n' +
                '                            </td>\n' +
                '                        </tr>\n' +
                '                        <tr><td style="border-collapse:collapse;font-size:1px;height:15px;line-height:1px" height="15"><br></td></tr>\n' +
                '                        <tr><td style="border-collapse:collapse;font-size:1px;height:15px;line-height:1px" height="15"><br></td></tr>\n' +
                '                        <!--- Properties loop start-->\n' +
                '                        <tr>\n' +
                '                            <td style="padding-top:30px;border-collapse:collapse;font-size:0;line-height:0" valign="top" align="center">\n' +
                '                                <img style="border:0;outline:none;color:#000;display:block;font-family:Arial,sans-serif;font-size:16px;line-height:18px" src="https://ci6.googleusercontent.com/proxy/HpR66_Z37gCtBBNNqMO1JCpyVyEPBJIC4FC77tBpL42Q74WnGRkhZg-_Y-9GWpnc6dIjwyDX63lsbbpTpVRfMcuUM0zuE0PrcpJS1X9QU0CzoSNHX5JiXPa7Vido4GGlRWceTofj9IRD9uJB77XdEKxtLjF-Rst99TCMnVcSlzF-mED7NTdKC6il6aHnDweUqOlr54d_QUs5j1UDy0oVqw0c5-TxJnoafM0svZEtGKAryT21E5tB0QXRmXWuTeeKsaAVEsDdvDpLjLNsd8Xk6fX9qkCgKwJ3iX_YRz3M-srB-_mWahRfp6DC4PdOR2GD6JHh5sT7Djn7ti96qHuoi5e7hjAvqFHyc2MngnXufuq8kzxwtKxe_9X3pMRrcA=s0-d-e1-ft#https://images.dubizzle.com/v1/files/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmbiI6Imp3eTM5cDdhOW1vODItRFVCSVpaTEUiLCJ3IjpbeyJmbiI6IjVqZXVpN3FmemllNjEtRFVCSVpaTEUiLCJzIjowLCJwIjoiY2VudGVyLDEiLCJhIjoxMDB9XX0._KCwNv9i1R02-BTO18nsVdmzbZL5Si2fymRMIOkAI0g/image%3Bp%3Dmain" alt="Check out this property" class="CToWUd" data-bit="iit" width="100%" height="337" border="0">\n' +
                '                            </td>\n' +
                '                        </tr>\n' +
                '                        <tr>\n' +
                '                            <td style="padding-top:20px;border-collapse:collapse;color:#2491cd;font-family:\'Proxima Nova\',Arial,sans-serif;font-size:24px;font-weight:bold;line-height:33px;padding-bottom:4px" valign="top" align="left">\n' +
                '                            3,200,000 AED\n' +
                '                            </td>\n' +
                '                      </tr>\n' +
                '                      <tr><td style="border-collapse:collapse;color:#2b2d2e;font-family:\'Proxima Nova\',Arial,sans-serif;font-size:20px;font-weight:600px;line-height:24px" valign="top" align="left">Luxuary 3 bed apartment for sale</td></tr>\n' +
                '                      <tr><td style="border-collapse:collapse;font-family:\'Proxima Nova\',Arial,sans-serif;font-size:14px;font-weight:400px;line-height:32px" valign="top" align="left">Dubai >> Emirates Living >> The Springs 9</td></tr>\n' +
                '                      <tr><td style="border-collapse:collapse;border-radius:6px;padding:10px 25px" valign="middle" bgcolor="#1b2d40" align="center"><a style="border-collapse:collapse;background:#1b2d40;color:#fff;font-family:Ubuntu,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:14px;margin:0;text-decoration:none;text-transform:none" href="https://dubricks.com/properties/property_id">Check property details</a></td></tr>\n' +
                '\n' +
                '                      <tr >\n' +
                '                        <td style="padding-top:30px;border-collapse:collapse;font-size:0;line-height:0" valign="top" align="center">\n' +
                '                            <img style="border:0;outline:none;color:#000;display:block;font-family:Arial,sans-serif;font-size:16px;line-height:18px" src="https://ci6.googleusercontent.com/proxy/HpR66_Z37gCtBBNNqMO1JCpyVyEPBJIC4FC77tBpL42Q74WnGRkhZg-_Y-9GWpnc6dIjwyDX63lsbbpTpVRfMcuUM0zuE0PrcpJS1X9QU0CzoSNHX5JiXPa7Vido4GGlRWceTofj9IRD9uJB77XdEKxtLjF-Rst99TCMnVcSlzF-mED7NTdKC6il6aHnDweUqOlr54d_QUs5j1UDy0oVqw0c5-TxJnoafM0svZEtGKAryT21E5tB0QXRmXWuTeeKsaAVEsDdvDpLjLNsd8Xk6fX9qkCgKwJ3iX_YRz3M-srB-_mWahRfp6DC4PdOR2GD6JHh5sT7Djn7ti96qHuoi5e7hjAvqFHyc2MngnXufuq8kzxwtKxe_9X3pMRrcA=s0-d-e1-ft#https://images.dubizzle.com/v1/files/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmbiI6Imp3eTM5cDdhOW1vODItRFVCSVpaTEUiLCJ3IjpbeyJmbiI6IjVqZXVpN3FmemllNjEtRFVCSVpaTEUiLCJzIjowLCJwIjoiY2VudGVyLDEiLCJhIjoxMDB9XX0._KCwNv9i1R02-BTO18nsVdmzbZL5Si2fymRMIOkAI0g/image%3Bp%3Dmain" alt="Check out this property" class="CToWUd" data-bit="iit" width="100%" height="337" border="0">\n' +
                '                        </td>\n' +
                '                    </tr>\n' +
                '                    <tr>\n' +
                '                        <td style="padding-top:20px;border-collapse:collapse;color:#2491cd;font-family:\'Proxima Nova\',Arial,sans-serif;font-size:24px;font-weight:bold;line-height:33px;padding-bottom:4px" valign="top" align="left">\n' +
                '                        3,200,000 AED\n' +
                '                        </td>\n' +
                '                  </tr>\n' +
                '                  <tr><td style="border-collapse:collapse;color:#2b2d2e;font-family:\'Proxima Nova\',Arial,sans-serif;font-size:20px;font-weight:600px;line-height:24px" valign="top" align="left">Luxuary 3 bed apartment for sale</td></tr>\n' +
                '                  <tr><td style="border-collapse:collapse;font-family:\'Proxima Nova\',Arial,sans-serif;font-size:14px;font-weight:400px;line-height:32px" valign="top" align="left">Dubai >> Emirates Living >> The Springs 9</td></tr>\n' +
                '                  <tr><td style="border-collapse:collapse;border-radius:6px;padding:10px 25px" valign="middle" bgcolor="#1b2d40" align="center"><a style="border-collapse:collapse;background:#1b2d40;color:#fff;font-family:Ubuntu,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:14px;margin:0;text-decoration:none;text-transform:none" href="https://dubricks.com/properties/property_id">Check property details</a></td></tr>\n' +
                '                  <!-- properties loop ends-->\n' +
                '\n' +
                '                  <!-- social media links-->\n' +
                '                  <tr>\n' +
                '                    <td style="padding-top:50px;border-collapse:collapse" valign="top" align="center">\n' +
                '                        <table style="border-collapse:collapse" cellspacing="0" cellpadding="0" border="0" align="center">\n' +
                '                        <tbody>\n' +
                '                            <tr>\n' +
                '                            <td style="border-collapse:collapse;width:32px" width="32" valign="top" align="center"><a style="border-collapse:collapse;text-decoration:none" href=""><img style="border:0;outline:none;color:#000;display:block;font-family:Arial,sans-serif;font-size:15px;font-weight:bold;line-height:18px" src="http://dubricks.com/assets/email-template/icon__fb.png" alt="FB" class="CToWUd" data-bit="iit" width="32" height="32" border="0"></a></td>\n' +
                '                            <td style="border-collapse:collapse;width:16px" width="16"><br></td>\n' +
                '                            <td style="border-collapse:collapse;width:32px" width="32" valign="top" align="center"><a style="border-collapse:collapse;text-decoration:none" href=""><img style="border:0;outline:none;color:#000;display:block;font-family:Arial,sans-serif;font-size:15px;font-weight:bold;line-height:18px" src="http://dubricks.com/assets/email-template/icon__instagram.png" alt="Insta" class="CToWUd" data-bit="iit" width="32" height="32" border="0"></a></td>\n' +
                '                            <td style="border-collapse:collapse;width:16px" width="16"><br></td>\n' +
                '                            <td style="border-collapse:collapse;width:32px" width="32" valign="top" align="center"><a style="border-collapse:collapse;text-decoration:none" href=""><img style="border:0;outline:none;color:#000;display:block;font-family:Arial,sans-serif;font-size:15px;font-weight:bold;line-height:18px" src="http://dubricks.com/assets/email-template/icon__youtube.png" alt="Youtube" class="CToWUd" data-bit="iit" width="32" height="32" border="0"></a></td>\n' +
                '                            <td style="border-collapse:collapse;width:16px" width="16"><br></td>\n' +
                '                            <td style="border-collapse:collapse;width:32px" width="32" valign="top" align="center"><a style="border-collapse:collapse;text-decoration:none" href=""><img style="border:0;outline:none;color:#000;display:block;font-family:Arial,sans-serif;font-size:15px;font-weight:bold;line-height:18px" src="http://dubricks.com/assets/email-template/icon__twitter.png" alt="Twitter" class="CToWUd" data-bit="iit" width="32" height="32" border="0"></a></td>\n' +
                '                            <td style="border-collapse:collapse;width:16px" width="16"><br></td>\n' +
                '                            </tr>\n' +
                '                        </tbody>\n' +
                '                        </table>\n' +
                '                    </td>\n' +
                '                </tr>\n' +
                '                <!-- unsubscribe link-->\n' +
                '                <tr>\n' +
                '                    <td style="padding-top:50px;padding-bottom: 20px;border-collapse:collapse;color:#757778;font-family:Helvetica,Arial,sans-serif;font-size:12px;line-height:15px" valign="top" align="center">This email was sent to you by <span class="il">Dubricks.com</span> with love. <a style="border-collapse:collapse;color:#757778;text-decoration:underline;white-space:nowrap" href="https://dubricks.com/emailUnsub" target="_blank">Unsubscribe</a>\n' +
                '                    </td>\n' +
                '                </tr>\n' +
                '                <!-- copyrights-->\n' +
                '                <tr><td style="padding-top:20px; border-top: 1px solid #efeeee;border-collapse:collapse;color:#757778;font-family:Helvetica,Arial,sans-serif;font-size:12px;line-height:15px" valign="top" align="center">Copyright Â© 2022 <span class="il">dubricks.com</span> All rights reserved. <span class="il">Dubricks.com</span>.<br>15th Floor, Blue Bay Towers, Dubai, UAE</td></tr>\n' +
                '                <!-- footer image-->\n' +
                '                <tr><td style="padding-top:20px; border-collapse:collapse;color:#757778;font-family:Helvetica,Arial,sans-serif;font-size:12px;line-height:15px" valign="top" align="center"><img width="100%" src="https://dubricks.com/assets/email-template/footer.png" /></td></tr>\n' +
                '                    </tbody>\n' +
                '                </table>\n' +
                '                </td>\n' +
                '            </tr>\n' +
                '            </tbody>\n' +
                '        </table>'
        }
    }
}
import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const NOTIFICATIONS_LIST = "notifications_list";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const CLEAR_NOTIFICATION_ERRORS = "clearNotificationErrors";

// mutation types
export const SET_NOTIFICATION_ERROR = "setNotificationError";
export const REMOVE_NOTIFICATION_ERRORS = "removeNotificationErrors";

const state = {
    errors: null
};


const actions = {
    [NOTIFICATIONS_LIST](context) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simple("api/notifications")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_NOTIFICATION_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_NOTIFICATION_ERROR, response.errors);
                });
        });
    },
    [CLEAR_NOTIFICATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/clearnotifications", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_NOTIFICATION_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_NOTIFICATION_ERROR, response.errors);
                });
        });
    },
    [CLEAR_NOTIFICATION_ERRORS](context) {
        context.commit(REMOVE_NOTIFICATION_ERRORS);
    },
};

const mutations = {
    [SET_NOTIFICATION_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_NOTIFICATION_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";

// action types
export const DEVELOPERS_LIST = "DEVELOPERS_LIST";
export const DEVELOPERS_SIMPLE_LIST = "DEVELOPERS_SIMPLE_LIST";
export const CREATE_DEVELOPER = "CREATE_DEVELOPER";
export const UPDATE_DEVELOPER = "UPDATE_DEVELOPER";
export const DELETE_DEVELOPER = "DELETE_DEVELOPER";
export const GET_DEVELOPER_DETAILS = "GET_DEVELOPER_DETAILS";
export const CLEAR_DEVELOPER_ERRORS = "CLEAR_DEVELOPER_ERRORS";

// mutation types
export const SET_DEVELOPER_ERRORS = "setDeveloperError";
export const REMOVE_DEVELOPER_ERRORS = "removeDeveloperErrors";

const state = {
  errors: null
};

const actions = {
  [DEVELOPERS_LIST](context, payload) {
    return new Promise(resolve => {
      ApiService.datatable("api/developers?type=" + payload.type + "&" + payload.q + payload.columns.join(""))
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_DEVELOPER_ERRORS, response.errors);
        });
    });
  },
  [DEVELOPERS_SIMPLE_LIST](context, payload) {
      return new Promise(resolve => {
        ApiService.datatable("api/developerssimple?q=" + payload.q)
            .then(({data}) => {
              if (data._metadata.outcomeCode === 0) {
                resolve(data);
              } else {
                this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
              }
            })
            .catch(({ response }) => {
              context.commit(SET_DEVELOPER_ERRORS, response.errors);
            });
      });
  },
  [GET_DEVELOPER_DETAILS](context, id) {
    return new Promise(resolve => {
      ApiService.get("api/getdeveloperdetail", id)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_DEVELOPER_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_DEVELOPER_ERRORS, response.errors);
        });
    });
  },
  [CREATE_DEVELOPER](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/createdeveloper", payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_DEVELOPER_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_DEVELOPER_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_DEVELOPER](context, payload) {
    return new Promise(resolve => {
      return ApiService.update("api/updatedeveloper", payload.slug, payload.data)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_DEVELOPER_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_DEVELOPER_ERRORS, response.errors);
        });
    });
  },
  [DELETE_DEVELOPER](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deletedeveloper", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_DEVELOPER_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_DEVELOPER_ERRORS, response.errors);
        });
    });
  },
  [CLEAR_DEVELOPER_ERRORS](context) {
    context.commit(REMOVE_DEVELOPER_ERRORS);
  }
};

const mutations = {
  [SET_DEVELOPER_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_DEVELOPER_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const PROJECTUNITTYPES_LIST = "PROJECTUNITTYPES_LIST";
export const CREATE_PROJECTUNITTYPES = "CREATE_PROJECTUNITTYPES";
export const UPDATE_PROJECTUNITTYPES = "UPDATE_PROJECTUNITTYPES";
export const DELETE_PROJECTUNITTYPES = "DELETE_PROJECTUNITTYPES";
export const DUPLICATE_PROJECTUNITTYPE = "DUPLICATE_PROJECTUNITTYPE";
export const GET_PROJECTUNITTYPES_DETAILS = "GET_PROJECTUNITTYPES_DETAILS";
export const PROJECTUNITTYPES_SIMPLE_LIST = "PROJECTUNITTYPES_SIMPLE_LIST";
export const PROJECTUNITTYPES_FEATURES_LIST = "PROJECTUNITTYPES_FEATURES_LIST";
export const CLEAR_PROJECTUNITTYPES_ERRORS = "CLEAR_PROJECTUNITTYPES_ERRORS";

// mutation types
export const SET_PROJECTUNITTYPES_ERROR = "setProjectUnitTypesErrors";
export const REMOVE_PROJECTUNITTYPES_ERRORS = "removeProjectUnitTypesErrors";

const state = {
    errors: null,
    uploadedProjectUnitTypeFeatures: []
};
const actions = {
    [PROJECTUNITTYPES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/projectunittypes?project_id=" + payload.project_id + '&type=' + payload.type + '&' + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTUNITTYPES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITTYPES_ERROR, response.errors);
                });
        });
    },
    [CREATE_PROJECTUNITTYPES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createprojectunittype", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTUNITTYPES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITTYPES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PROJECTUNITTYPES](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateprojectunittype", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTUNITTYPES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITTYPES_ERROR, response.errors);
                });
        });
    },
    [DUPLICATE_PROJECTUNITTYPE](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/duplicateprojectunittype", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTUNITTYPES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITTYPES_ERROR, response.errors);
                });
        });
    },
    [DELETE_PROJECTUNITTYPES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteprojectunittype", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTUNITTYPES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITTYPES_ERROR, response.errors);
                });
        });
    },
    [GET_PROJECTUNITTYPES_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getprojectunittypedetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTUNITTYPES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITTYPES_ERROR, response.errors);
                });
        });
    },
    [CLEAR_PROJECTUNITTYPES_ERRORS](context) {
        context.commit(REMOVE_PROJECTUNITTYPES_ERRORS);
    },
    [PROJECTUNITTYPES_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/projectunittypessimple?project_id=" + payload.project_id + '&type=' + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_PROJECTUNITTYPES_ERROR, response.errors);
            });
        });
    },
    [PROJECTUNITTYPES_FEATURES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/projectunittypefeatures?put_id=" + payload.put_id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_PROJECTUNITTYPES_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_PROJECTUNITTYPES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_PROJECTUNITTYPES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {LOGOUT} from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
// action types
export const DEVELOPMENTTYPES_SIMPLE_LIST = "DEVELOPMENTTYPES_SIMPLE_LIST";
export const PROPERTYTYPES_SIMPLE_LIST = "PROPERTYTYPES_SIMPLE_LIST";
export const COMPLETION_STATUSES = "COMPLETION_STATUSES";
export const ROLE_PERMISSIONS_LIST = "ROLE_PERMISSIONS_LIST";
export const CURRENCY_SIMPLE_LIST = "CURRENCY_SIMPLE_LIST";
export const NATIONALITY_SIMPLE_LIST = "NATIONALITY_SIMPLE_LIST";
export const SAVE_LOG = "SAVE_LOG";
const actions = {
    [SAVE_LOG](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/savelog", payload.data);
        });
    },
    [DEVELOPMENTTYPES_SIMPLE_LIST]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.get("api/developmenttypessimple")
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        }
                    });
            });
        } else {
            this.$store.dispatch(LOGOUT);
        }
    },
    [PROPERTYTYPES_SIMPLE_LIST]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.get("api/propertytypessimple")
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        }
                    });
            });
        } else {
            this.$store.dispatch(LOGOUT);
        }
    },
    [COMPLETION_STATUSES]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.get("api/completionstatusessimple")
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        }
                    });
            });
        } else {
            this.$store.dispatch(LOGOUT);
        }
    },
    [ROLE_PERMISSIONS_LIST]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.get("api/permissionssimple")
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        }
                    });
            });
        } else {
            this.$store.dispatch(LOGOUT);
        }
    },
    [CURRENCY_SIMPLE_LIST]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.get("api/currenciessimple")
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        }
                    });
            });
        } else {
            this.$store.dispatch(LOGOUT);
        }
    },
    [NATIONALITY_SIMPLE_LIST]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.get("api/nationalitiessimple")
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        }
                    });
            });
        } else {
            this.$store.dispatch(LOGOUT);
        }
    },
};
export default {
    actions,
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const PROJECTTYPICALFLOORPLANS_LIST = "projecttypicalfloorplans_list";
export const CREATE_PROJECTTYPICALFLOORPLANS = "create_projecttypicalfloorplans";
export const UPDATE_PROJECTTYPICALFLOORPLANS = "update_projecttypicalfloorplans";
export const DELETE_PROJECTTYPICALFLOORPLANS = "delete_projecttypicalfloorplans";
export const GET_PROJECTTYPICALFLOORPLANS_DETAILS = "get_projecttypicalfloorplans_details";
export const PROJECTTYPICALFLOORPLANS_SIMPLE_LIST = "projecttypicalfloorplans_simple_details";
export const CLEAR_PROJECTTYPICALFLOORPLANS_ERRORS = "clearProjectTypicalFloorPlansErrors";

// mutation types
export const SET_PROJECTTYPICALFLOORPLANS_ERROR = "setProjectTypicalFloorPlansErrors";
export const REMOVE_PROJECTTYPICALFLOORPLANS_ERRORS = "removeProjectTypicalFloorPlansErrors";

const state = {
    errors: null,
};
const actions = {
    [PROJECTTYPICALFLOORPLANS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/projecttypicalfloorplans?project_id=" + payload.project_id  + '&' + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, response.errors);
                });
        });
    },
    [CREATE_PROJECTTYPICALFLOORPLANS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createprojecttypicalfloorplan", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PROJECTTYPICALFLOORPLANS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateprojecttypicalfloorplan", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, response.errors);
                });
        });
    },
    [DELETE_PROJECTTYPICALFLOORPLANS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteprojecttypicalfloorplan", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, response.errors);
                });
        });
    },
    [GET_PROJECTTYPICALFLOORPLANS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getprojecttypicalfloorplandetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_PROJECTTYPICALFLOORPLANS_ERRORS](context) {
        context.commit(REMOVE_PROJECTTYPICALFLOORPLANS_ERRORS);
    },
    [PROJECTTYPICALFLOORPLANS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/projecttypicalfloorplanssimple?project_id=" + payload.project_id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_PROJECTTYPICALFLOORPLANS_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_PROJECTTYPICALFLOORPLANS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_PROJECTTYPICALFLOORPLANS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

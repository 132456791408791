import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const VIEWS_LIST = "views_list";
export const CREATE_VIEWS = "create_views";
export const UPDATE_VIEWS = "update_views";
export const DELETE_VIEWS = "delete_views";
export const GET_VIEWS_DETAILS = "get_views_details";
export const VIEWS_SIMPLE_LIST = "views_simple_details";
export const CLEAR_VIEWS_ERRORS = "clearViewsErrors";

// mutation types
export const SET_VIEWS_ERROR = "setViewsError";
export const REMOVE_VIEWS_ERRORS = "removeViewsErrors";

const state = {
    errors: null
};
const actions = {
    [VIEWS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/views?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_VIEWS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_VIEWS_ERROR, response.errors);
                });
        });
    },
    [CREATE_VIEWS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createview", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_VIEWS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_VIEWS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_VIEWS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateview", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_VIEWS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_VIEWS_ERROR, response.errors);
                });
        });
    },
    [DELETE_VIEWS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteview", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_VIEWS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_VIEWS_ERROR, response.errors);
                });
        });
    },
    [GET_VIEWS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getviewdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_VIEWS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_VIEWS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_VIEWS_ERRORS](context) {
        context.commit(REMOVE_VIEWS_ERRORS);
    },
    [VIEWS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/viewssimple?type=" + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_VIEWS_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_VIEWS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_VIEWS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const PROJECTUNITDETAILSPM_LIST = "PROJECTUNITDETAILSPM_LIST";
export const CREATE_PROJECTUNITDETAILSPM = "CREATE_PROJECTUNITDETAILSPM";
export const UPDATE_PROJECTUNITDETAILSPM = "UPDATE_PROJECTUNITDETAILSPM";
export const DELETE_PROJECTUNITDETAILSPM = "DELETE_PROJECTUNITDETAILSPM";
export const GET_PROJECTUNITDETAILSPM_DETAILS = "GET_PROJECTUNITDETAILSPM_DETAILS";
export const GET_PROJECTUNITDETAILSPM_DETAILS_BY_UNIT_NUMBER = "GET_PROJECTUNITDETAILSPM_DETAILS_BY_UNIT_NUMBER";
export const PROJECTUNITDETAILSPM_SIMPLE_LIST = "PROJECTUNITDETAILSPM_SIMPLE_LIST";
export const CLEAR_PROJECTUNITDETAILSPM_ERRORS = "CLEAR_PROJECTUNITDETAILSPM_ERRORS";

// mutation types
export const SET_PROJECTUNITDETAILSPM_ERROR = "SET_PROJECTUNITDETAILSPM_ERROR";
export const REMOVE_PROJECTUNITDETAILSPM_ERRORS = "REMOVE_PROJECTUNITDETAILSPM_ERRORS";

const state = {
    errors: null,
    yearlyServiceCharges: '',
    monthlyCoolingCharges: ''
};
const actions = {
    [PROJECTUNITDETAILSPM_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/projectunitdetailspm?project_id=" + payload.project_id + '&' + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTUNITDETAILSPM_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITDETAILSPM_ERROR, response.errors);
                });
        });
    },
    [CREATE_PROJECTUNITDETAILSPM](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createprojectunitdetailpm", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTUNITDETAILSPM_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITDETAILSPM_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PROJECTUNITDETAILSPM](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateprojectunitdetailpm", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTUNITDETAILSPM_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITDETAILSPM_ERROR, response.errors);
                });
        });
    },
    [DELETE_PROJECTUNITDETAILSPM](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteprojectunitdetailpm", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PROJECTUNITDETAILSPM_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITDETAILSPM_ERROR, response.errors);
                });
        });
    },
    [GET_PROJECTUNITDETAILSPM_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getprojectunitdetaildetailpm", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTUNITDETAILSPM_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITDETAILSPM_ERROR, response.errors);
                });
        });
    },
    [GET_PROJECTUNITDETAILSPM_DETAILS_BY_UNIT_NUMBER](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getprojectunitdetaildetailpmbyunitnumber?project_id=" + payload.project_id + "&unit_no=" + payload.unit_no)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PROJECTUNITDETAILSPM_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PROJECTUNITDETAILSPM_ERROR, response.errors);
                });
        });
    },
    [CLEAR_PROJECTUNITDETAILSPM_ERRORS](context) {
        context.commit(REMOVE_PROJECTUNITDETAILSPM_ERRORS);
    },
    [PROJECTUNITDETAILSPM_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/projectunitdetailspmsimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
};

const mutations = {
    [SET_PROJECTUNITDETAILSPM_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_PROJECTUNITDETAILSPM_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const PUBLISHINGSTATUSES_LIST = "publishing_statuses_list";
export const CREATE_PUBLISHINGSTATUSE = "create_publishing_statuse";
export const UPDATE_PUBLISHINGSTATUSE = "update_publishing_statuse";
export const DELETE_PUBLISHINGSTATUSE = "delete_publishing_statuse";
export const GET_PUBLISHINGSTATUSE_DETAILS = "get_publishing_statuse_details";
export const PUBLISHINGSTATUSES_SIMPLE_LIST = "publishing_statuses_simple_details";
export const CLEAR_PUBLISHINGSTATUSE_ERRORS = "clearPublishingStatuseErrors";

// mutation types
export const SET_PUBLISHINGSTATUSES_ERROR = "setPurposesError";
export const REMOVE_PUBLISHINGSTATUSES_ERRORS = "removePurposesErrors";

const state = {
    errors: null
};
const actions = {
    [PUBLISHINGSTATUSES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/publishingstatus?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PUBLISHINGSTATUSES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PUBLISHINGSTATUSES_ERROR, response.errors);
                });
        });
    },
    [CREATE_PUBLISHINGSTATUSE](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createpublishingstatus", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PUBLISHINGSTATUSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PUBLISHINGSTATUSES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PUBLISHINGSTATUSE](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatepublishingstatus", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PUBLISHINGSTATUSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PUBLISHINGSTATUSES_ERROR, response.errors);
                });
        });
    },
    [DELETE_PUBLISHINGSTATUSE](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletepublishingstatus", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PUBLISHINGSTATUSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PUBLISHINGSTATUSES_ERROR, response.errors);
                });
        });
    },
    [GET_PUBLISHINGSTATUSE_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getpublishingstatus", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PUBLISHINGSTATUSES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PUBLISHINGSTATUSES_ERROR, response.errors);
                });
        });
    },
    [CLEAR_PUBLISHINGSTATUSE_ERRORS](context) {
        context.commit(REMOVE_PUBLISHINGSTATUSES_ERRORS);
    },
    [PUBLISHINGSTATUSES_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/publishingstatusessimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
};

const mutations = {
    [SET_PUBLISHINGSTATUSES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_PUBLISHINGSTATUSES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const ROADLOCATIONS_LIST = "roadlocations_list";
export const CREATE_ROADLOCATIONS = "create_roadlocations";
export const UPDATE_ROADLOCATIONS = "update_roadlocations";
export const DELETE_ROADLOCATIONS = "delete_roadlocations";
export const GET_ROADLOCATIONS_DETAILS = "get_roadlocations_details";
export const ROADLOCATIONS_SIMPLE_LIST = "roadlocations_simple_details";
export const CLEAR_ROADLOCATIONS_ERRORS = "clearRoadLocationsErrors";

// mutation types
export const SET_ROADLOCATIONS_ERROR = "setRoadLocationsErrors";
export const REMOVE_ROADLOCATIONS_ERRORS = "removeRoadLocationsErrors";

const state = {
    errors: null
};
const actions = {
    [ROADLOCATIONS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/roadlocations?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_ROADLOCATIONS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_ROADLOCATIONS_ERROR, response.errors);
                });
        });
    },
    [CREATE_ROADLOCATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createroadlocation", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_ROADLOCATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_ROADLOCATIONS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_ROADLOCATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updateroadlocation", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_ROADLOCATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_ROADLOCATIONS_ERROR, response.errors);
                });
        });
    },
    [DELETE_ROADLOCATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteroadlocation", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_ROADLOCATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_ROADLOCATIONS_ERROR, response.errors);
                });
        });
    },
    [GET_ROADLOCATIONS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getroadlocationdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_ROADLOCATIONS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_ROADLOCATIONS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_ROADLOCATIONS_ERRORS](context) {
        context.commit(REMOVE_ROADLOCATIONS_ERRORS);
    },
    [ROADLOCATIONS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/roadlocationssimple?type=" + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_ROADLOCATIONS_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_ROADLOCATIONS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_ROADLOCATIONS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

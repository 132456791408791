import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const CARDS_LIST = "cards_list";
export const CREATE_CARDS = "create_cards";
export const UPDATE_CARDS = "update_cards";
export const DELETE_CARDS = "delete_cards";
export const GET_CARDS_DETAILS = "get_cards_details";
export const CARDS_SIMPLE_LIST = "cards_simple_details";
export const CLEAR_CARDS_ERRORS = "clearCardsErrors";

// mutation types
export const SET_CARDS_ERROR = "setCardsErrors";
export const REMOVE_CARDS_ERRORS = "removeCardsErrors";

const state = {
    errors: null
};
const actions = {
    [CARDS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/cards?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CARDS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_CARDS_ERROR, response.errors);
                });
        });
    },
    [CREATE_CARDS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createcard", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CARDS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CARDS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_CARDS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatecard", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CARDS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CARDS_ERROR, response.errors);
                });
        });
    },
    [DELETE_CARDS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletecard", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_CARDS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_CARDS_ERROR, response.errors);
                });
        });
    },
    [GET_CARDS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getcarddetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_CARDS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_CARDS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_CARDS_ERRORS](context) {
        context.commit(REMOVE_CARDS_ERRORS);
    },
    [CARDS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/cardssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
};

const mutations = {
    [SET_CARDS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_CARDS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const SALUTATIONS_LIST = "salutations_list";
export const CREATE_SALUTATIONS = "create_salutations";
export const UPDATE_SALUTATIONS = "update_salutations";
export const DELETE_SALUTATIONS = "delete_salutations";
export const GET_SALUTATIONS_DETAILS = "get_salutations_details";
export const SALUTATIONS_SIMPLE_LIST = "salutations_simple_details";
export const CLEAR_SALUTATIONS_ERRORS = "clearSalutationsErrors";

// mutation types
export const SET_SALUTATIONS_ERROR = "setSalutationsErrors";
export const REMOVE_SALUTATIONS_ERRORS = "removeSalutationsErrors";

const state = {
    errors: null
};
const actions = {
    [SALUTATIONS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/salutations?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SALUTATIONS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SALUTATIONS_ERROR, response.errors);
                });
        });
    },
    [CREATE_SALUTATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createsalutation", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_SALUTATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_SALUTATIONS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_SALUTATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatesalutation", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_SALUTATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_SALUTATIONS_ERROR, response.errors);
                });
        });
    },
    [DELETE_SALUTATIONS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletesalutation", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_SALUTATIONS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_SALUTATIONS_ERROR, response.errors);
                });
        });
    },
    [GET_SALUTATIONS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getsalutationdetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_SALUTATIONS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_SALUTATIONS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_SALUTATIONS_ERRORS](context) {
        context.commit(REMOVE_SALUTATIONS_ERRORS);
    },
    [SALUTATIONS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/salutationssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
};

const mutations = {
    [SET_SALUTATIONS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_SALUTATIONS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const PURPOSES_LIST = "purposes_list";
export const CREATE_PURPOSES = "create_purposes";
export const UPDATE_PURPOSES = "update_purposes";
export const DELETE_PURPOSES = "delete_purposes";
export const GET_PURPOSES_DETAILS = "get_purposes_details";
export const PURPOSES_SIMPLE_LIST = "purposes_simple_details";
export const CLEAR_PURPOSES_ERRORS = "clearPurposesErrors";

// mutation types
export const SET_PURPOSES_ERROR = "setPurposesError";
export const REMOVE_PURPOSES_ERRORS = "removePurposesErrors";

const state = {
    errors: null
};
const actions = {
    [PURPOSES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/purposes?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PURPOSES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PURPOSES_ERROR, response.errors);
                });
        });
    },
    [CREATE_PURPOSES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createpurpose", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PURPOSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PURPOSES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_PURPOSES](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatepurpose", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PURPOSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PURPOSES_ERROR, response.errors);
                });
        });
    },
    [DELETE_PURPOSES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletepurpose", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_PURPOSES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_PURPOSES_ERROR, response.errors);
                });
        });
    },
    [GET_PURPOSES_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getpurposedetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_PURPOSES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_PURPOSES_ERROR, response.errors);
                });
        });
    },
    [CLEAR_PURPOSES_ERRORS](context) {
        context.commit(REMOVE_PURPOSES_ERRORS);
    },
    [PURPOSES_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/purposessimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
};

const mutations = {
    [SET_PURPOSES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_PURPOSES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

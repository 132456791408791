import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const FREQUENCIES_LIST = "frequencies_list";
export const CREATE_FREQUENCIES = "create_frequencies";
export const UPDATE_FREQUENCIES = "update_frequencies";
export const DELETE_FREQUENCIES = "delete_frequencies";
export const GET_FREQUENCIES_DETAILS = "get_frequencies_details";
export const FREQUENCIES_SIMPLE_LIST = "frequencies_simple_details";
export const FREQUENCIES_PP_SIMPLE_LIST = "frequencies_pp_simple_details";
export const CLEAR_FREQUENCIES_ERRORS = "clearFrequencyErrors";

// mutation types
export const SET_FREQUENCIES_ERROR = "setFrequencyErrors";
export const REMOVE_FREQUENCIES_ERRORS = "removeFrequencyErrors";

const state = {
    errors: null
};
const actions = {
    [FREQUENCIES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/frequencies?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_FREQUENCIES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_FREQUENCIES_ERROR, response.errors);
                });
        });

    },
    [CREATE_FREQUENCIES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createfrequency", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_FREQUENCIES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_FREQUENCIES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_FREQUENCIES](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatefrequency", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_FREQUENCIES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_FREQUENCIES_ERROR, response.errors);
                });
        });
    },
    [DELETE_FREQUENCIES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletefrequency", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_FREQUENCIES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_FREQUENCIES_ERROR, response.errors);
                });
        });
    },
    [GET_FREQUENCIES_DETAILS](context, id) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.get("api/getfrequencydetail", id)
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                            context.commit(SET_FREQUENCIES_ERROR, data.errors);
                        }
                    })
                    .catch(({response}) => {
                        context.commit(SET_FREQUENCIES_ERROR, response.errors);
                    });
            });
    },
    [CLEAR_FREQUENCIES_ERRORS](context) {
        context.commit(REMOVE_FREQUENCIES_ERRORS);
    },
    [FREQUENCIES_SIMPLE_LIST](context, payload) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.datatable("api/frequenciessimple?type=" + payload.type)
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        }
                    }).catch(({response}) => {
                    context.commit(SET_FREQUENCIES_ERROR, response.errors);
                });
            });
    },
    [FREQUENCIES_PP_SIMPLE_LIST](context) {
            ApiService.setHeader();
            return new Promise(resolve => {
                ApiService.datatable("api/frequenciesppsimple")
                    .then(({data}) => {
                        if (data._metadata.outcomeCode === 0) {
                            resolve(data);
                        } else {
                            this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        }
                    }).catch(({response}) => {
                    context.commit(SET_FREQUENCIES_ERROR, response.errors);
                });
            });
    },
};

const mutations = {
    [SET_FREQUENCIES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_FREQUENCIES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

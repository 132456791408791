import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const DASHBOARD_TRANSACTIONS_LIST = "DASHBOARD_TRANSACTIONS_LIST";
export const ADS_LIST = "ADS_LIST";
export const DASHBOARD_YEARLY_GRAPH = "DASHBOARD_YEARLY_GRAPH";
export const DASHBOARD_PT_GRAPH = "DASHBOARD_PT_GRAPH";
export const DASHBOARD_PPSF_GRAPH = "DASHBOARD_PPSF_GRAPH";
export const DASHBOARD_PBB_GRAPH = "DASHBOARD_PBB_GRAPH";
export const DASHBOARD_PBB_TABLE = "DASHBOARD_PBB_TABLE";
export const TRANSACTIONS_TITLES = "TRANSACTIONS_TITLES";
export const GET_UNIT_HISTORY = "GET_UNIT_HISTORY";
export const ADS_DEPS = "ADS_DEPS";
export const SAVE_REPORT = "SAVE_REPORT";
export const CLEAR_DASHBOARD_ERRORS = "clearDashboardsErrors";

// mutation types
export const SET_DASHBOARD_ERROR = "setDashboardsError";
export const REMOVE_DASHBOARD_ERRORS = "removeDashboardsErrors";

const state = {
    errors: null
};
const actions = {
    [DASHBOARD_TRANSACTIONS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/dashboardtransactionslist?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [ADS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/dashboardadslist?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [DASHBOARD_YEARLY_GRAPH](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/yearlygraph?" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [DASHBOARD_PT_GRAPH](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/ptgraph?" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [DASHBOARD_PPSF_GRAPH](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/avgppsfgraph?" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [DASHBOARD_PBB_GRAPH](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/avgpbbgraph?" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [DASHBOARD_PBB_TABLE](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/avgpbbtable?" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [TRANSACTIONS_TITLES](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/transactionstitles?q=" + payload.q)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [ADS_DEPS](context) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/adsdeps")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [GET_UNIT_HISTORY](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/unithistory/" + payload.id + "?un=" + payload.un)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DASHBOARD_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [SAVE_REPORT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/savereport", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DASHBOARD_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_DASHBOARD_ERROR, response.errors);
                });
        });
    },
    [CLEAR_DASHBOARD_ERRORS](context) {
        context.commit(REMOVE_DASHBOARD_ERRORS);
    },
};

const mutations = {
    [SET_DASHBOARD_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_DASHBOARD_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const DEVELOPMENTTYPES_LIST = "developmenttypes_list";
export const CREATE_DEVELOPMENTTYPES = "create_developmenttypes";
export const UPDATE_DEVELOPMENTTYPES = "update_developmenttypes";
export const DELETE_DEVELOPMENTTYPES = "delete_developmenttypes";
export const GET_DEVELOPMENTTYPES_DETAILS = "get_developmenttypes_details";
export const DEVELOPMENTTYPES_TREE_LIST = "DEVELOPMENTTYPES_TREE_LIST";
export const DEVELOPMENTTYPES_SIMPLE_LIST = "developmenttypes_simple_details";
export const DEVELOPMENTTYPES_SIMPLE_LIST_PM = "developmenttypes_simple_details_pm";
export const CLEAR_DEVELOPMENTTYPES_ERRORS = "clearDevelopmentTypesErrors";

// mutation types
export const SET_DEVELOPMENTTYPES_ERROR = "setDevelopmentTypesErrors";
export const REMOVE_DEVELOPMENTTYPES_ERRORS = "removeDevelopmentTypesErrors";

const state = {
    errors: null
};
const actions = {
    [DEVELOPMENTTYPES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/developmenttypes?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DEVELOPMENTTYPES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, response.errors);
                });
        });
    },
    [CREATE_DEVELOPMENTTYPES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createdevelopmenttype", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_DEVELOPMENTTYPES](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatedevelopmenttype", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, response.errors);
                });
        });
    },
    [DELETE_DEVELOPMENTTYPES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletedevelopmenttype", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, response.errors);
                });
        });
    },
    [DEVELOPMENTTYPES_TREE_LIST](context) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getdevelopmenttypetree")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_DEVELOPMENTTYPES_ERROR, response.errors);
            });
        });
    },
    [GET_DEVELOPMENTTYPES_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getdevelopmenttypedetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DEVELOPMENTTYPES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, response.errors);
                });
        });
    },
    [CLEAR_DEVELOPMENTTYPES_ERRORS](context) {
        context.commit(REMOVE_DEVELOPMENTTYPES_ERRORS);
    },
    [DEVELOPMENTTYPES_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/developmenttypessimple?type=" + payload.type + '&is_property=' + payload.is_property)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, response.errors);
                });
        });
    },
    [DEVELOPMENTTYPES_SIMPLE_LIST_PM](context) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/developmenttypessimplepm")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DEVELOPMENTTYPES_ERROR, response.errors);
                });
        });
    },
};

const mutations = {
    [SET_DEVELOPMENTTYPES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_DEVELOPMENTTYPES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

import ApiService from "@/core/services/api.service";
import { SET_SNACK } from "@/core/services/store/snackbar.module";
import JwtService from "@/core/services/jwt.service";
import {LOGOUT} from "@/core/services/store/auth.module";

// action types
export const UPLOADED_FILES = "UPLOADED_FILES";
export const GET_ALL_FOLDERS = "GET_ALL_FOLDERS";
export const UPLOAD_FILE = "upload_file";
export const DELETE_UPLOADED_FILE = "delete_uploaded_file";
export const DELETE_UPLOADED_FILES = "delete_uploaded_files";
export const UPDATE_FILES = "update_files";
export const ADD_FOLDER = "ADD_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const DELETE_UPLOADED_LINK = "delete_uploaded_link";
export const CLEAR_UPLOAD_ERRORS = "clearUploadErrors";

// mutation types
export const SET_UPLOAD_ERRORS = "setUploadError";
export const REMOVE_UPLOAD_ERRORS = "removeUploadErrors";

const state = {
  errors: null,
  uploadedStateFiles: [],
  uploadedStateOtherFiles: {},
  uploadedStateOtherFilesSecond: {},
  uploadedStateLinks: {}
};

const actions = {
  [UPLOADED_FILES](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        ApiService.simpledatatable("api/getfiles?modelId=" + payload.modelId + "&model=" + payload.model + "&moduleOfFolder=" + payload.moduleOfFolder + "&folderId=" + payload.folderId + "&modelKey=" + payload.modelKey + "&moduleTitle=" + payload.moduleTitle + '&' + payload.q + payload.columns.join(''))
            .then(({data}) => {
              resolve(data);
            })
            .catch(({response}) => {
              // console.log(response);
              context.commit(SET_UPLOAD_ERRORS, response.errors);
            });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [GET_ALL_FOLDERS](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise(resolve => {
        ApiService.datatable("api/getfolders?moduleOfFolder=" + payload.moduleOfFolder + "&moduleTitle=" + payload.moduleTitle)
            .then(({data}) => {
              resolve(data);
            })
            .catch(({response}) => {
              // console.log(response);
              context.commit(SET_UPLOAD_ERRORS, response.errors);
            });
      });
    } else {
      this.$store.dispatch(LOGOUT);
    }
  },
  [UPLOAD_FILE](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/uploadfile", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_UPLOAD_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_UPLOAD_ERRORS, response.errors);
        });
    });
  },
  [ADD_FOLDER](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/addfolder", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_UPLOAD_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_UPLOAD_ERRORS, response.errors);
        });
    });
  },
  [DELETE_FOLDER](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deletefolder", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_UPLOAD_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_UPLOAD_ERRORS, response.errors);
        });
    });
  },
  [DELETE_UPLOADED_FILE](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deleteuploadedfile", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            this.dispatch(SET_SNACK, {
              clr: "success",
              msg: data._metadata.message
            });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_UPLOAD_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_UPLOAD_ERRORS, response.errors);
        });
    });
  },
  [DELETE_UPLOADED_FILES](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deleteuploadedfiles", payload)
        .then(({ data }) => {
          if (data._metadata.outcomeCode === 0) {
            // this.dispatch(SET_SNACK, {
            //   clr: "success",
            //   msg: data._metadata.message
            // });
            resolve(data);
          } else {
            this.dispatch(SET_SNACK, {
              clr: "error",
              msg: data._metadata.message
            });
            context.commit(SET_UPLOAD_ERRORS, data.errors);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_UPLOAD_ERRORS, response.errors);
        });
    });
  },
  [UPDATE_FILES](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/updatefiles", payload)
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              this.dispatch(SET_SNACK, {
                clr: "success",
                msg: data._metadata.message
              });
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_UPLOAD_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            context.commit(SET_UPLOAD_ERRORS, response.errors);
          });
    });
  },
  [DELETE_UPLOADED_LINK](context, payload) {
    return new Promise(resolve => {
      return ApiService.post("api/deleteuploadedlink", payload)
          .then(({ data }) => {
            if (data._metadata.outcomeCode === 0) {
              this.dispatch(SET_SNACK, {
                clr: "success",
                msg: data._metadata.message
              });
              resolve(data);
            } else {
              this.dispatch(SET_SNACK, {
                clr: "error",
                msg: data._metadata.message
              });
              context.commit(SET_UPLOAD_ERRORS, data.errors);
            }
          })
          .catch(({ response }) => {
            context.commit(SET_UPLOAD_ERRORS, response.errors);
          });
    });
  },
  [CLEAR_UPLOAD_ERRORS](context) {
    context.commit(REMOVE_UPLOAD_ERRORS);
  }
};

const mutations = {
  [SET_UPLOAD_ERRORS](state, error) {
    state.errors = error;
  },
  [REMOVE_UPLOAD_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations
};

import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const LEADS_LIST = "leads_list";
export const ASSIGNED_LEADS_LIST = "assigned_leads_list";
export const SHARED_LEADS_LIST = "shared_leads_list";
export const LEADS_SIMPLE_LIST = "leads_simple_list";
export const SHARE_LEADS = "share_leads";
export const CREATE_LEADS = "create_leads";
export const UPDATE_LEADS = "update_leads";
export const DELETE_LEADS = "delete_leads";
export const DUPLICATE_LEAD = "duplicate_lead";
export const GET_LEADS_DETAILS = "get_leads_details";
export const UPDATE_LEADS_STATUS = "update_leads_status";
export const GET_LEAD_COMMENT = "GET_LEAD_COMMENT";
export const SAVE_LEAD_COMMENT = "SAVE_LEAD_COMMENT";
export const OFFERED_LISTINGS_LIST = "OFFERED_LISTINGS_LIST";
export const SAVE_OFFERED_LISTINGS = "SAVE_OFFERED_LISTINGS";
export const DELETE_OFFERED_LISTING = "DELETE_OFFERED_LISTING";
export const SEND_OFFERED_LISTINGS_EMAIL = "SEND_OFFERED_LISTINGS_EMAIL";
export const LEADS_DASHBOARD_STATS = "LEADS_DASHBOARD_STATS";
export const LEADS_DASHBOARD_ALL_COUNT = "LEADS_DASHBOARD_ALL_COUNT";
export const CLEAR_LEADS_ERRORS = "clearLeadsErrors";

// mutation types
export const SET_LEADS_ERROR = "setLeadsError";
export const REMOVE_LEADS_ERRORS = "removeLeadsErrors";


const state = {
    errors: null
};
const actions = {
    [LEADS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/leads?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LEADS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [LEADS_DASHBOARD_ALL_COUNT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/leadsdashboardallcount?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LEADS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [ASSIGNED_LEADS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/assignedleads?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LEADS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [SHARED_LEADS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/sharedleads?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LEADS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [SHARE_LEADS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/shareleads", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [CREATE_LEADS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createlead", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_LEADS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatelead", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [DUPLICATE_LEAD](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/duplicatelead", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [DELETE_LEADS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletelead", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [GET_LEADS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getleaddetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LEADS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_LEADS_STATUS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/updateleadstatus", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [GET_LEAD_COMMENT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/getleadcomments/" + payload.lead_id + '?page=' + payload.page)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LEADS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [LEADS_DASHBOARD_STATS](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/leadsdashboardstats?fromDateSearch=" + payload.fromDateSearch + '&toDateSearch=' + payload.toDateSearch + '&viewFull=' + payload.viewFull)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LEADS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    // console.log(response);
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [SAVE_LEAD_COMMENT](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            return ApiService.post("api/saveleadcomment", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    // console.log(errors);
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [LEADS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/leadssimple?type=" + payload.type + '&ref_id=' + payload.refId)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_LEADS_ERROR, response.errors);
            });
        });
    },
    [OFFERED_LISTINGS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/offeredlistings?lead_id=" + payload.lead_id + '&' + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_LEADS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [SAVE_OFFERED_LISTINGS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/saveofferedlistings", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [DELETE_OFFERED_LISTING](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deleteofferedlisting", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [SEND_OFFERED_LISTINGS_EMAIL](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/sendofferedlistingsemail", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_LEADS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_LEADS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_LEADS_ERRORS](context) {
        context.commit(REMOVE_LEADS_ERRORS);
    },
};

const mutations = {
    [SET_LEADS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_LEADS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

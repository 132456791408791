import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const MERGED_DATA_LIST = "MERGED_DATA_LIST";
export const UPDATE_MERGED_DATA = "UPDATE_MERGED_DATA";
export const DELETE_MERGED_DATA = "DELETE_MERGED_DATA";
export const CLEAR_MERGED_DATA_ERRORS = "CLEAR_MERGED_DATA_ERRORS";

// mutation types
export const SET_MERGED_DATA_ERROR = "SET_MERGED_DATA_ERROR";
export const REMOVE_MERGED_DATA_ERRORS = "REMOVE_MERGED_DATA_ERRORS";


const state = {
    errors: null,
};
const actions = {
    [MERGED_DATA_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/mergeddata?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_MERGED_DATA_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_MERGED_DATA_ERROR, response.errors);
                });
        });
    },
    [UPDATE_MERGED_DATA](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/updatemergeddata", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_MERGED_DATA_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_MERGED_DATA_ERROR, response.errors);
                });
        });
    },
    [DELETE_MERGED_DATA](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletemergeddata", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_MERGED_DATA_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_MERGED_DATA_ERROR, response.errors);
                });
        });
    },
    [CLEAR_MERGED_DATA_ERRORS](context) {
        context.commit(REMOVE_MERGED_DATA_ERRORS);
    },
};

const mutations = {
    [SET_MERGED_DATA_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_MERGED_DATA_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

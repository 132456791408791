import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const DOMAINS_LIST = "domains_list";
export const CREATE_DOMAINS = "create_domains";
export const UPDATE_DOMAINS = "update_domains";
export const DELETE_DOMAINS = "delete_domains";
export const GET_DOMAINS_DETAILS = "get_domains_details";
export const DOMAINS_SIMPLE_LIST = "domains_simple_details";
export const CLEAR_DOMAINS_ERRORS = "clearDomainsErrors";

// mutation types
export const SET_DOMAINS_ERROR = "setDomainsErrors";
export const REMOVE_DOMAINS_ERRORS = "removeDomainsErrors";

const state = {
    errors: null
};
const actions = {
    [DOMAINS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/domains?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DOMAINS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DOMAINS_ERROR, response.errors);
                });
        });
    },
    [CREATE_DOMAINS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createdomain", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DOMAINS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DOMAINS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_DOMAINS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatedomain", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DOMAINS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DOMAINS_ERROR, response.errors);
                });
        });
    },
    [DELETE_DOMAINS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletedomain", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_DOMAINS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_DOMAINS_ERROR, response.errors);
                });
        });
    },
    [GET_DOMAINS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getdomaindetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_DOMAINS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_DOMAINS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_DOMAINS_ERRORS](context) {
        context.commit(REMOVE_DOMAINS_ERRORS);
    },
    [DOMAINS_SIMPLE_LIST]() {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/domainssimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                });
        });
    },
};

const mutations = {
    [SET_DOMAINS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_DOMAINS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

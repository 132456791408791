import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: "dashboard",
                    meta: {
                        auth: true,
                        permission: "dashboard.view"
                    },
                    component: () => import("@/view/pages/realstate/dashboard/Dashboard.vue")
                },
                {
                    path: "/leads-dashboard",
                    name: "leads-dashboard",
                    meta: {
                        auth: true,
                        permission: "dashboard.view"
                    },
                    component: () => import("@/view/pages/realstate/dashboard/LeadsDashboard.vue")
                },
                {
                    path: "/listings-dashboard",
                    name: "listings-dashboard",
                    meta: {
                        auth: true,
                        permission: "dashboard.view"
                    },
                    component: () => import("@/view/pages/realstate/dashboard/ListingsDashboard.vue")
                },
                {
                    path: "/market-insights",
                    name: "market-insights",
                    meta: {
                        auth: true,
                        permission: "dashboard.view"
                    },
                    component: () => import("@/view/pages/realstate/market_insights/MarketInsights.vue")
                },
                {
                    path: "/developments-management",
                    name: "developments-management",
                    component: () =>
                        import("@/view/pages/realstate/Vuetify.vue"),
                    children: [
                        {
                            path: "master-developments",
                            name: "master-developments",
                            meta: {
                                auth: true,
                                permission: "master_developments.view"
                            },
                            component: () => import("@/view/pages/realstate/developments/MasterDevelopments")
                        },
                        {
                            path: "sub-developments",
                            name: "sub-developments",
                            meta: {
                                auth: true,
                                permission: "sub_developments.view"
                            },
                            component: () => import("@/view/pages/realstate/developments/SubDevelopments")
                        },
                        {
                            path: "projects",
                            name: "projects",
                            meta: {
                                auth: true,
                                permission: "projects.view"
                            },
                            component: () => import("@/view/pages/realstate/projects/Projects")
                        },
                        {
                            path: "pm-unmatched-data",
                            name: "pm-unmatched-data",
                            component: () => import("@/view/pages/realstate/merging/UnmatchedData")
                        },
                    ]
                },
                {
                    path: "/listings",
                    name: "listings",
                    meta: {
                        auth: true,
                        permission: "listings.view"
                    },
                    component: () => import("@/view/pages/realstate/listings/Listings.vue")
                },
                {
                    path: "/leads",
                    name: "leads",
                    meta: {
                        auth: true,
                        permission: "leads.view"
                    },
                    component: () => import("@/view/pages/realstate/leads/Leads.vue")
                },
                {
                    path: "/contacts-management",
                    name: "contacts-management",
                    component: () =>
                        import("@/view/pages/realstate/Vuetify.vue"),
                    children: [
                        {
                            path: "contact-roles",
                            name: "contact-roles",
                            meta: {
                                auth: true,
                                permission: "contact_roles.view"
                            },
                            component: () => import("@/view/pages/realstate/contact_roles/ContactRoles")
                        },
                        {
                            path: "salutations",
                            name: "salutations",
                            meta: {
                                auth: true,
                                permission: "salutations.view"
                            },
                            component: () => import("@/view/pages/realstate/salutations/Salutations")
                        },
                        {
                            path: "occupations",
                            name: "occupations",
                            meta: {
                                auth: true,
                                permission: "occupations.view"
                            },
                            component: () => import("@/view/pages/realstate/occupations/Occupations")
                        },
                        {
                            path: "domains",
                            name: "domains",
                            meta: {
                                auth: true,
                                permission: "domains.view"
                            },
                            component: () => import("@/view/pages/realstate/domains/Domains")
                        },
                        {
                            path: "labels",
                            name: "labels",
                            meta: {
                                auth: true,
                                permission: "labels.view"
                            },
                            component: () => import("@/view/pages/realstate/labels/Labels")
                        },
                        {
                            path: "contacts",
                            name: "contacts",
                            meta: {
                                auth: true,
                                permission: "contacts.view"
                            },
                            component: () => import("@/view/pages/realstate/contacts_management/Contacts")
                        },
                    ]
                },
                {
                    path: "users-panel",
                    name: "users-panel",
                    component: () =>
                        import("@/view/pages/realstate/Vuetify.vue"),
                    children: [
                        // {
                        //     path: "teams",
                        //     name: "teams",
                        //     meta: {
                        //         auth: true,
                        //         permission: "teams.view"
                        //     },
                        //     component: () => import("@/view/pages/realstate/teams/Teams")
                        // },
                        {
                            path: "users",
                            name: "users",
                            meta: {
                                auth: true,
                                permission: "users.view"
                            },
                            component: () => import("@/view/pages/realstate/users/Users")
                        },
                        {
                            path: "vendors",
                            name: "vendors",
                            meta: {
                                auth: true,
                                permission: "vendors.view"
                            },
                            component: () => import("@/view/pages/realstate/vendors/Vendors")
                        }
                    ]
                },
                {
                    path: "/setup",
                    name: "setup",
                    component: () =>
                        import("@/view/pages/realstate/Vuetify.vue"),
                    children: [
                        {
                            path: "countries",
                            name: "countries",
                            meta: {
                                auth: true,
                                permission: "countries.view"
                            },
                            component: () => import("@/view/pages/realstate/countries/Countries")
                        },
                        {
                            path: "cities",
                            name: "cities",
                            meta: {
                                auth: true,
                                permission: "cities.view"
                            },
                            component: () => import("@/view/pages/realstate/cities/Cities")
                        },
                        {
                            path: "sources",
                            name: "sources",
                            meta: {
                                auth: true,
                                permission: "sources.view"
                            },
                            component: () => import("@/view/pages/realstate/sources/Sources")
                        },
                        {
                            path: "completion-status",
                            name: "completion-status",
                            meta: {
                                auth: true,
                                permission: "completion_status.view"
                            },
                            component: () => import("@/view/pages/realstate/completion_statuses/CompletionStatuses")
                        },
                        {
                            path: "comment-titles",
                            name: "comment-titles",
                            meta: {
                                auth: true,
                                permission: "comment_titles.view"
                            },
                            component: () => import("@/view/pages/realstate/comment_titles/CommentTitles")
                        },
                        {
                            path: "cards",
                            name: "cards",
                            meta: {
                                auth: true,
                                permission: "cards.view"
                            },
                            component: () => import("@/view/pages/realstate/cards/Cards")
                        },
                        {
                            path: "views",
                            name: "views",
                            meta: {
                                auth: true,
                                permission: "views.view"
                            },
                            component: () => import("@/view/pages/realstate/views/Views")
                        },
                        {
                            path: "brands",
                            name: "brands",
                            meta: {
                                auth: true,
                                permission: "brands.view"
                            },
                            component: () => import("@/view/pages/realstate/brands/Brands")
                        },
                        {
                            path: "purposes",
                            name: "purposes",
                            meta: {
                                auth: true,
                                permission: "purpose.view"
                            },
                            component: () => import("@/view/pages/realstate/purposes/Purposes")
                        },
                        {
                            path: "publishing-status",
                            name: "publishing-status",
                            meta: {
                                auth: true,
                                permission: "publishing_status.view"
                            },
                            component: () => import("@/view/pages/realstate/publishing_statuses/PublishingStatuses")
                        },
                        {
                            path: "inquiry-status",
                            name: "inquiry-status",
                            meta: {
                                auth: true,
                                permission: "inquiry_status.view"
                            },
                            component: () => import("@/view/pages/realstate/inquiry_statuses/InquiryStatuses")
                        },
                        {
                            path: "property-types",
                            name: "property-types",
                            meta: {
                                auth: true,
                                permission: "property_types.view"
                            },
                            component: () => import("@/view/pages/realstate/property_types/PropertyTypes")
                        },
                        {
                            path: "development-types",
                            name: "development-types",
                            meta: {
                                auth: true,
                                permission: "development_types.view"
                            },
                            component: () => import("@/view/pages/realstate/development_types/DevelopmentTypes")
                        },
                        {
                            path: "road-locations",
                            name: "road-locations",
                            meta: {
                                auth: true,
                                permission: "road_locations.view"
                            },
                            component: () => import("@/view/pages/realstate/road_locations/RoadLocations")
                        },
                        {
                            path: "location-remarks",
                            name: "location-remarks",
                            meta: {
                                auth: true,
                                permission: "location_remarks.view"
                            },
                            component: () => import("@/view/pages/realstate/location_remarks/LocationRemarks")
                        },
                        {
                            path: "life-cycles",
                            name: "life-cycles",
                            meta: {
                                auth: true,
                                permission: "life_cycles.view"
                            },
                            component: () => import("@/view/pages/realstate/life_cycles/LifeCycles")
                        },
                        {
                            path: "special-classifications",
                            name: "special-classifications",
                            meta: {
                                auth: true,
                                permission: "special_classifications.view"
                            },
                            component: () => import("@/view/pages/realstate/special_classifications/SpecialClassifications")
                        },
                        {
                            path: "amenities",
                            name: "amenities",
                            meta: {
                                auth: true,
                                permission: "amenities.view"
                            },
                            component: () => import("@/view/pages/realstate/amenities/Amenities")
                        },
                        {
                            path: "rent-frequencies",
                            name: "rent-frequencies",
                            meta: {
                                auth: true,
                                permission: "frequencies.view"
                            },
                            component: () => import("@/view/pages/realstate/frequencies/Frequencies")
                        },
                        {
                            path: "pp-frequencies",
                            name: "pp-frequencies",
                            meta: {
                                auth: true,
                                permission: "frequencies.view"
                            },
                            component: () => import("@/view/pages/realstate/frequencies/PaymentPlanFrequencies")
                        }
                    ]
                },
                {
                    path: "/portals-management",
                    name: "portals-management",
                    component: () =>
                        import("@/view/pages/realstate/Vuetify.vue"),
                    children: [
                        {
                            path: "menus",
                            name: "menus",
                            meta: {
                                auth: true,
                                permission: "menus.view"
                            },
                            component: () => import("@/view/pages/realstate/menus/Menus.vue")
                        },
                        {
                            path: "pages",
                            name: "pages",
                            meta: {
                                auth: true,
                                permission: "pages.view"
                            },
                            component: () => import("@/view/pages/realstate/pages/Pages.vue")
                        },
                        {
                            path: "banners",
                            name: "banners",
                            meta: {
                                auth: true,
                                permission: "banners.view"
                            },
                            component: () => import("@/view/pages/realstate/banners/Banners.vue")
                        },
                        {
                            path: "news",
                            name: "news",
                            meta: {
                                auth: true,
                                permission: "news.view"
                            },
                            component: () => import("@/view/pages/realstate/news/News.vue")
                        },
                        {
                            path: "blog",
                            name: "blog",
                            meta: {
                                auth: true,
                                permission: "blog.view"
                            },
                            component: () => import("@/view/pages/realstate/posts/Posts.vue")
                        }
                    ]
                },
                {
                    path: "/reports-panel",
                    name: "reports-panel",
                    component: () =>
                        import("@/view/pages/realstate/Vuetify.vue"),
                    children: [
                        {
                            path: "reports",
                            name: "reports",
                            meta: {
                                auth: true,
                                permission: "reports.view"
                            },
                            component: () => import("@/view/pages/realstate/reports/Reports.vue")
                        },
                        {
                            path: "logs",
                            name: "logs",
                            meta: {
                                auth: true,
                                permission: "logs.view"
                            },
                            component: () => import("@/view/pages/realstate/logs/Logs.vue")
                        }
                    ]
                },
            ]
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/view/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/view/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/view/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/view/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/view/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/view/pages/error/Error-6.vue")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "ghost-login",
                    path: "secure/v1/ghost/login",
                    component: () => import("@/view/pages/auth/GhostLogin")
                },
                // {
                //     name: "register",
                //     path: "/register",
                //     component: () => import("@/view/pages/auth/Register")
                // },
                {
                    name: "forgot-password",
                    path: "/forgot-password",
                    component: () => import("@/view/pages/auth/ForgotPassword")
                },
                {
                    name: "reset-password",
                    path: "/reset-password/:id",
                    component: () => import("@/view/pages/auth/ResetPassword")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/realstate/Vuetify.vue"),
            children: [
                {
                    name: "area-report",
                    path: "/area-report",
                    component: () => import("@/view/pages/realstate/reports/ReportDetails")
                },
                {
                    name: "ads-area-report",
                    path: "/ads-area-report",
                    component: () => import("@/view/pages/realstate/reports/AdsReportDetails")
                },
                {
                    name: "listing-price-report",
                    path: "/listing-price-report",
                    component: () => import("@/view/pages/realstate/reports/ListingPriceReport")
                }
            ]
        },
        {
            path: "*",
            redirect: "/login"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        }
    ]
});

import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import moment from "moment";
import {SAVE_LOG} from "@/core/services/store/commons.module";

export default {
    data() {
        return {
            // screenResolution: '',
            parentFilter: [],
            tinyKey: "9l0vh6o626oqntkywmvqf8cmd9d7qxr8d4ky121k44kkkjf0",
            tinyEditorInit: {
                force_br_newlines: true,
                force_p_newlines: false,
                forced_root_block: "", // Needed for 3.x
                branding: false,
                height: 500,
                menubar: false,
                images_dataimg_filter: function (img) {
                    return img.hasAttribute("internal-blob");
                },
                images_upload_url: "image",
                automatic_uploads: false,
                plugins: [
                    "advlist autolink lists code link image imagetools charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code wordcount fullscreen"
                ],
                toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | table | removeformat | code | link | image | fullscreen"
            },
            typesFilter: [
                {value: "Master Developments", title: "Master Developments"},
                {value: "Sub Developments", title: "Sub Developments"},
                {value: "Projects", title: "Projects"},
                {value: "Properties", title: "Properties"}
            ],
            labelsFilter: [
                {value: "Email", title: "Email"},
                {value: "Phone", title: "Phone"},
                {value: "Fax", title: "Fax"},
                {value: "Address", title: "Address"},
                {value: "Passport Number", title: "Passport Number"},
                {
                    value: "National/Resident's Identification",
                    title: "National/Resident's Identification"
                },
                {value: "Event", title: "Event"},
                {value: "Website", title: "Website"},
                {value: "Relationship", title: "Relationship"},
                {value: "Chat", title: "Chat"},
                {value: "Internet Call", title: "Internet Call"}
            ],
            unitsFilter: [
                {value: "Square Feet", title: "Square Feet"},
                {value: "Square Meter", title: "Square Meter"},
                {value: "Millimeter", title: "Millimeter"},
                {value: "Square Yard", title: "Square Yard"},
                {value: "Acre", title: "Acre"},
                {value: "Hectare", title: "Hectare"},
                {value: "Marla New", title: "Marla New"},
                {value: "Marla Old", title: "Marla Old"},
                {value: "Kanal New", title: "Kanal New"},
                {value: "Kanal Old", title: "Kanal Old"},
                {value: "Dimensions", title: "Dimensions"}
            ],
            lctFilter: [
                {value: 1, title: "Cash Buyer"},
                {value: 2, title: "Mortgage Buyer"}
            ],
            unitsFilterForUsers: [
                {value: "Square Feet", title: "Square Feet"},
                {value: "Square Meter", title: "Square Meter"},
                {value: "Millimeter", title: "Millimeter"},
                {value: "Square Yard", title: "Square Yard"},
                {value: "Acre", title: "Acre"},
                {value: "Hectare", title: "Hectare"},
                {value: "Marla New", title: "Marla New"},
                {value: "Marla Old", title: "Marla Old"},
                {value: "Kanal New", title: "Kanal New"},
                {value: "Kanal Old", title: "Kanal Old"}
            ],
            authoritiesFilter: [
                {
                    value: "Trade Licence Number Issuing Authority",
                    title: "Trade Licence Number Issuing Authority"
                },
                {
                    value: "Organization Registration Number Issuing Authority",
                    title: "Organization Registration Number Issuing Authority"
                },
                {
                    value: "Broker Registration Number Issuing Authority",
                    title: "Broker Registration Number Issuing Authority"
                },
                {
                    value: "Developer Registration Number Issuing Authority",
                    title: "Developer Registration Number Issuing Authority"
                }
            ],
            lsmFilter: [
                {value: 0, title: "Shared"},
                {value: 1, title: "Private"}
            ],
            questions: [
                {value: 0, title: "No"},
                {value: 1, title: "Yes"}
            ],
            matchedFilter: [
                {value: 0, title: "UnMatched"},
                {value: 1, title: "Matched"}
            ],
            tenancyFilter: [
                {value: 1, title: "Rented"},
                {value: 2, title: "Vacant"}
            ],
            showFilter: [
                {value: 0, title: "All"},
                {value: 1, title: "Assigned To Me"}
            ],
            galleyPurposeFilters: [
                {value: "Floor Plans", title: "Floor Plans"},
                {value: "Photos", title: "Photos"}
            ],
            purposeFilters: [
                {value: "sale", title: "Sale"},
                {value: "rent", title: "Rent"},
            ],
            fsFilters: [
                {value: "furnished", title: "Furnished"},
                {value: "unfurnished", title: "Unfurnished"}
            ],
            bedsFilter: [
                {value: "Studio", title: "Studio"},
                {value: "1", title: "1"},
                {value: "2", title: "2"},
                {value: "3", title: "3"},
                {value: "4", title: "4"},
                {value: "5", title: "5"},
                {value: "6", title: "6"},
                {value: "7", title: "7"},
                {value: "8", title: "8"},
                {value: "9", title: "9"},
                {value: "10", title: "10"},
                {value: "11", title: "11"},
                {value: "12", title: "12"},
                {value: "13", title: "13"},
                {value: "14", title: "14"},
                {value: "15", title: "15"},
                {value: "16", title: "16"},
                {value: "17", title: "17"},
                {value: "18", title: "18"},
                {value: "19", title: "19"},
                {value: "20", title: "20"},
                {value: "21", title: "21"},
                {value: "22", title: "22"},
                {value: "23", title: "23"},
                {value: "24", title: "24"},
                {value: "25", title: "25"},
                {value: "26", title: "26"},
            ],
            unitTypesFilter: [
                {value: "Apartment", title: "Apartment"},
                {value: "Hotel Apartment", title: "Hotel Apartment"},
                {value: "Land", title: "Land"},
                {value: "Office", title: "Office"},
                {value: "Retail", title: "Retail"},
                {value: "Industrial", title: "Industrial"},
                {value: "Ground", title: "Ground"},
                {value: "Villa", title: "Villa"},
                {value: "Sports Club", title: "Sports Club"},
                {value: "Labour Camp", title: "Labour Camp"},
                {value: "Townhouse", title: "Townhouse"},
                {value: "Factory", title: "Factory"},
                {value: "Whole Building", title: "Whole Building"},
                {value: "Warehouse", title: "Warehouse"}
            ],
            primaryResaleFilter: [
                {value: "Primary", title: "Primary"},
                {value: "Resale", title: "Resale"}
            ],
            priceSortFilter: [
                {value: "asc", title: "Low to High"},
                {value: "desc", title: "High to Low"}
            ],
            frequencySortFilter: [
                {value: "Yearly", title: "Yearly"},
                {value: "Half Yearly", title: "Half Yearly"},
                {value: "Quarterly", title: "Quarterly"},
                {value: "Monthly", title: "Monthly"},
            ],
            typeSortFilter: [
                {value: 0, title: "Residential"},
                {value: 1, title: "Commercial"}
            ],
            typeSortFilterAds: [
                {value: "residential", title: "Residential"},
                {value: "commercial", title: "Commercial"}
            ],
            rfSortFilterAds: [
                {value: "Bi-Yearly", title: "Bi-Yearly"},
                {value: "daily", title: "Daily"},
                {value: "monthly", title: "Monthly"},
                {value: "Quarterly", title: "Quarterly"},
                {value: "weekly", title: "Weekly"},
                {value: "yearly", title: "Yearly"}
            ],
            sourcesFilterAdsAds: [
                {value: "dubizzle", title: "Dubizzle"},
                {value: "bayut", title: "Bayut"},
                // {value: "PF", title: "PF"}
            ],
            dateRangeFilter: [
                {value: "1", title: "1 Month"},
                {value: "2", title: "3 Months"},
                {value: "3", title: "6 Months"},
                {value: "4", title: "1 Year"},
                {value: "5", title: "Year To Date"}
            ],
            frequencyFilter: [
                {value: "1 Month", title: "1 Month"},
                {value: "3 Months", title: "3 Months"},
                {value: "6 Months", title: "6 Months"},
                {value: "12 Months", title: "12 Months"}
            ],
            reportTypeFilter: [
                {value: "1", title: "Developments with MP details"},
                {value: "2", title: "Developments without MP details"},
                {value: "3", title: "Projects with MP details"},
                {value: "4", title: "Projects without MP details"},
                {value: "5", title: "Total Number Of Units"},
                // {value: "6", title: "Property Type Wise Unit Break Down"},
                {value: "7", title: "How Many Nationalities"},
                {value: "8", title: "How Many Email Addresses and Phone Numbers"},
                // {value: "9", title: "How Many Mobile Numbers"},
            ],
            sharingFilters: [
                {value: 1, title: "Users"},
                {value: 2, title: "Vendors"}
            ],
            sharingTypesFilters: [
                {value: 1, title: "Country Wise"},
                {value: 2, title: "Development Wise"},
                {value: 3, title: "Project Wise"},
                // {value: 4, title: "All"}
            ],
            emailSharingTypesFilters: [
                {value: 1, title: "Development Details"},
                {value: 2, title: "Project Details"},
                {value: 3, title: "Listing Details"}
            ],
            leadStatuses: [
                {value: 0, title: "Reject"},
                {value: 1, title: "Accept"}
            ],
            leadStatusesFilter: [
                {value: 1, title: "Pending"},
                {value: 2, title: "Accepted"},
                {value: 3, title: "Rejected"}
            ],
            isDimensions: false,
            activeTab: 0,
            activeTabInner: 0,
            activeTabDetail: 0,
            activeTabDetailInner: 0,
            activeTabSecond: 0,
            innerEditedItem: {},
            apiTimeout: null,
            mainDataTableSelection: [],
            countDialog: false,
            countDialogModule: '',
            countDialogModuleTitle: '',
            treeItemsPrev: [{id: '0', type: 'all'}],
            showMore: false,
            showClass: "",
            showLess: false,
            // totalRecordsDT: 0,
            lypTemplate: '<div style="text-align: center"><a href="https://dubricks.com/list-your-property"><img src="' + process.env.VUE_APP_ADMIN_API_URL + '/public/images/email-templates/list-your-property.jpeg' + '" alt="List Your Property"></a></div>',
            lypAfsTemplate: '<div style="text-align: center"><a href="https://afsrealestate.com/list-your-property"><img src="' + process.env.VUE_APP_ADMIN_API_URL + '/public/images/email-templates/list-your-property-afs.jpg' + '" alt="List Your Property"></a></div>'
        };
    },
    methods: {
        // hasRole(role) {
        //   if (store.state.auth.roles.length !== 0) {
        //     return store.state.auth.roles.some(spec => {
        //       return spec === role;
        //     });
        //   }
        // },

        hasPermission(permission) {
            if (store.state.auth.permissions.length !== 0) {
                return store.state.auth.permissions.some(spec => {
                    return spec === permission;
                });
            }
        },

        getAppUrl() {
            return ApiService.appUrl;
        },

        checkFileExtension(extension) {
            return extension === '.jpg' ||
                extension === '.JPG' ||
                extension === '.png' ||
                extension === '.PNG' ||
                extension === '.jpeg' ||
                extension === '.JPEG' ||
                extension === '.jfif' ||
                extension === '.JFIF';
        },

        isNumber(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode !== 46 &&
                charCode > 31 &&
                (charCode < 48 || charCode > 57)
            ) {
                return evt.preventDefault();
            }
            return true;
            // if (!/^[0-9]+$/.test(event.key) || event.key === ".")
            //   return event.preventDefault();
        },

        onPaste(evt) {
            if (isNaN(evt.clipboardData.getData('text'))) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        formatDateAndTime(date) {
            return date ? moment(date).format("DD-MMM-YYYY, h:mm a") : "";
            // return moment(date).format('DD/MM/YYYY, h:mm a')
        },

        formatMomentDate(date) {
            return date ? moment(date).format("DD-MMM-YYYY") : "";
            // return moment(date).format('DD/MM/YYYY, h:mm a')
        },

        getPicture(item) {
            if (item.image_public_url) {
                return item.image_public_url;
            } else {
                return process.env.BASE_URL + "media/place_holders/no-image.png";
            }
        },

        getGalleryImage(object) {
            if (object) {
                return this.$store.state.appUrl + '/' + object.url;
            } else {
                return process.env.BASE_URL + "media/place_holders/no-image.png";
            }
        },

        getImagePlaceHolder() {
            return process.env.BASE_URL + "media/place_holders/no-image.png";
        },

        unitCalculator(unit, input, value) {
            if (unit === "Square Feet") {
                return value * input;
            } else if (unit === "Square Meter") {
                let sq_ft = input * 10.764;
                return value * sq_ft;
            } else if (unit === "Square Yard") {
                let sq_ft = input * 9;
                return value * sq_ft;
            } else if (unit === "Acre") {
                let sq_ft = input * 43560;
                return value * sq_ft;
            } else if (unit === "Marla New") {
                let sq_ft = input * 225;
                return value * sq_ft;
            } else if (unit === "Marla Old") {
                let sq_ft = input * 272;
                return value * sq_ft;
            } else if (unit === "Kanal New") {
                let sq_ft = input * 4050;
                return value * sq_ft;
            } else if (unit === "Kanal Old") {
                let sq_ft = input * 5440;
                return value * sq_ft;
            }
        },

        unitConverter(fromUnit, value, toUnit) {
            if (fromUnit && value) {
                let data = {};
                value = parseFloat(value)
                if (fromUnit === "Square Feet") {
                    data['Square Feet'] = value
                    data['Square Meter'] = value / 10.764
                    data['Square Yard'] = value / 9
                    data['Acre'] = value / 43560
                    data['Hectare'] = value / 107639
                    return data[toUnit].toFixed(2);
                } else if (fromUnit === "Square Meter") {
                    data['Square Feet'] = value * 10.764
                    data['Square Meter'] = value
                    data['Square Yard'] = value * 1.196
                    data['Acre'] = value / 4047
                    data['Hectare'] = value / 10000
                    return data[toUnit].toFixed(2);
                } else if (fromUnit === "Square Yard") {
                    data['Square Feet'] = value * 9
                    data['Square Meter'] = value / 1.196
                    data['Square Yard'] = value
                    data['Acre'] = value / 4840
                    data['Hectare'] = value / 11960
                    return data[toUnit].toFixed(2);
                } else if (fromUnit === "Acre") {
                    data['Square Feet'] = value * 43560
                    data['Square Meter'] = value * 4047
                    data['Square Yard'] = value * 4840
                    data['Acre'] = value
                    data['Hectare'] = value / 2.471
                    return data[toUnit].toFixed(2);
                } else if (fromUnit === "Hectare") {
                    data['Square Feet'] = value * 107639
                    data['Square Meter'] = value * 10000
                    data['Square Yard'] = value * 11960
                    data['Acre'] = value * 2.471
                    data['Hectare'] = value
                    return data[toUnit].toFixed(2);
                }
            } else {
                return ''
            }
        },

        checkPropertyType(obj) {
            if ((obj && obj.id === 28) || (obj && obj.parent_id === 28)) {
                return 'Plot';
            } else if
            (
                (obj && obj.id === 39) || (obj && obj.parent_id === 39) ||
                (obj && obj.id === 40) || (obj && obj.parent_id === 40) ||
                (obj && obj.id === 41) || (obj && obj.parent_id === 41)
            ) {
                return 'WareHouse';
            } else if
            (
                (obj && obj.id === 42) || (obj && obj.parent_id === 42) ||
                (obj && obj.id === 43) || (obj && obj.parent_id === 43) ||
                (obj && obj.id === 44) || (obj && obj.parent_id === 44)
            ) {
                return 'Labor Camps';
            } else {
                return '';
            }
        },

        autocomplateSelectAll(type, data) {
            if (type === 'permissions') {
                this.innerEditedItem.permission_ids = [];
                for (let permission in data) {
                    this.innerEditedItem.permission_ids.push(data[permission].id);
                }
            }
        },

        autocomplateUnSelectAll(type) {
            if (type === 'permissions') {
                this.innerEditedItem.permission_ids = [];
            }
        },

        formatDate(date) {
            if (!date) return null
            const [year, day, month] = date.split('-')
            return `${month}-${day}-${year}`
        },

        parseDate(date) {
            if (!date) return null
            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },

        replaceCommaLine(data) {
            //convert string to array and remove whitespace
            let dataToArray = data.split(',').map(item => item.trim());
            //convert array to string replacing comma with new line
            return dataToArray.join("<br>");
        },

        replaceNewLine(data) {
            //convert string to array and remove whitespace
            let dataToArray = data.split('\n').map(item => item.trim());
            //convert array to string replacing comma with new line
            return dataToArray.join("<br>");
        },

        addTS(e) {
            let val = e.target.value;
            if (val) {
                val = val.replace(/[^0-9\.]/g, '');
                let valArr = val.split('.');
                valArr[0] = (parseInt(valArr[0], 10)).toLocaleString();
                val = valArr.join('.');
            } else {
                val = ''
            }
            return val;
        },

        addTSBV(val) {
            if (val) {
                val = val.toString().replace(/[^0-9\.]/g, '');
                let valArr = val.split('.');
                valArr[0] = (parseInt(valArr[0], 10)).toLocaleString();
                val = valArr.join('.');
            } else {
                val = ''
            }
            return val;
        },

        addTSBVZ(val) {
            if (val) {
                val = val.toString().replace(/[^0-9\.]/g, '');
                let valArr = val.split('.');
                valArr[0] = (parseInt(valArr[0], 10)).toLocaleString();
                val = valArr.join('.');
            } else {
                val = '0'
            }
            return val;
        },

        bulkSelectChange(status, items) {
            if (status) {
                this.mainDataTableSelection = items;
            } else {
                this.mainDataTableSelection = [];
            }
        },

        bulkOfferSelectChange(status, items) {
            if (status) {
                this.offerListingsSelection = items;
            } else {
                this.offerListingsSelection = [];
            }
        },

        setHtml(html) {
            return html.replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, "\"")
                .replace(/&#39;/g, "\'")
                .replace(/&amp;/g, "&");
        },

        datatableScrollToTop(ref) {
            let table = this.$refs[ref];
            let wrapper = table.$el.querySelector('div.v-data-table__wrapper');
            this.$vuetify.goTo(table, {container: wrapper}); // to header
        },

        scrollToTop(ref) {
            let table = this.$refs[ref];
            let wrapper = table.$el.querySelector('div.v-card');

            //this.$vuetify.goTo(table); // to table
            this.$vuetify.goTo(table, {container: wrapper}); // to header
        },

        getPercentage(val, amt) {
            if (val) {
                let value = parseFloat(val.replace(/,(?=\d{3})/g, ''));
                let amount = parseFloat(amt.replace(/,(?=\d{3})/g, ''));
                return this.addTSBV(((value / 100) * amount).toFixed())
            }
        },

        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },

        saveLog(data, type) {
            data.logType = type
            this.$store.dispatch(SAVE_LOG, {data:data})
        }
    }
}

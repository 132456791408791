import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const BRANDS_LIST = "brands_list";
export const CREATE_BRANDS = "create_brands";
export const UPDATE_BRANDS = "update_brands";
export const DELETE_BRANDS = "delete_brands";
export const GET_BRANDS_DETAILS = "get_brands_details";
export const BRANDS_SIMPLE_LIST = "brands_simple_details";
export const CLEAR_BRANDS_ERRORS = "clearBrandsErrors";

// mutation types
export const SET_BRANDS_ERROR = "setBrandsError";
export const REMOVE_BRANDS_ERRORS = "removeBrandsErrors";

const state = {
    errors: null
};
const actions = {
    [BRANDS_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/brands?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_BRANDS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_BRANDS_ERROR, response.errors);
                });
        });
    },
    [CREATE_BRANDS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createbrand", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BRANDS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BRANDS_ERROR, response.errors);
                });
        });
    },
    [UPDATE_BRANDS](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatebrand", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BRANDS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BRANDS_ERROR, response.errors);
                });
        });
    },
    [DELETE_BRANDS](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletebrand", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_BRANDS_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_BRANDS_ERROR, response.errors);
                });
        });
    },
    [GET_BRANDS_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getbranddetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_BRANDS_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_BRANDS_ERROR, response.errors);
                });
        });
    },
    [CLEAR_BRANDS_ERRORS](context) {
        context.commit(REMOVE_BRANDS_ERRORS);
    },
    [BRANDS_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/brandssimple?type=" + payload.type)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_BRANDS_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_BRANDS_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_BRANDS_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};

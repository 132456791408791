import ApiService from "@/core/services/api.service";
import {SET_SNACK} from "@/core/services/store/snackbar.module";

// action types
export const COMMENTTITLES_LIST = "commenttitles_list";
export const CREATE_COMMENTTITLES = "create_commenttitles";
export const UPDATE_COMMENTTITLES = "update_commenttitles";
export const DELETE_COMMENTTITLES = "delete_commenttitles";
export const GET_COMMENTTITLES_DETAILS = "get_commenttitles_details";
export const COMMENTTITLES_SIMPLE_LIST = "commenttitles_simple_details";
export const CLEAR_COMMENTTITLES_ERRORS = "clearCommentTitlesErrors";

// mutation types
export const SET_COMMENTTITLES_ERROR = "setCommentTitlesErrors";
export const REMOVE_COMMENTTITLES_ERRORS = "removeCommentTitlesErrors";

const state = {
    errors: null
};
const actions = {
    [COMMENTTITLES_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.simpledatatable("api/commenttitles?" + payload.q + payload.columns.join(''))
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_COMMENTTITLES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_COMMENTTITLES_ERROR, response.errors);
                });
        });
    },
    [CREATE_COMMENTTITLES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/createcommenttitle", payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_COMMENTTITLES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_COMMENTTITLES_ERROR, response.errors);
                });
        });
    },
    [UPDATE_COMMENTTITLES](context, payload) {
        return new Promise(resolve => {
            return ApiService.update("api/updatecommenttitle", payload.slug, payload.data).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_COMMENTTITLES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_COMMENTTITLES_ERROR, response.errors);
                });
        });
    },
    [DELETE_COMMENTTITLES](context, payload) {
        return new Promise(resolve => {
            return ApiService.post("api/deletecommenttitle", payload).then(({data}) => {
                if (data._metadata.outcomeCode === 0) {
                    this.dispatch(SET_SNACK, {clr: 'success', msg: data._metadata.message});
                    resolve(data);
                } else {
                    this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    context.commit(SET_COMMENTTITLES_ERROR, data.errors);
                }
            })
                .catch(({response}) => {
                    context.commit(SET_COMMENTTITLES_ERROR, response.errors);
                });
        });
    },
    [GET_COMMENTTITLES_DETAILS](context, id) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.get("api/getcommenttitledetail", id)
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                        context.commit(SET_COMMENTTITLES_ERROR, data.errors);
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_COMMENTTITLES_ERROR, response.errors);
                });
        });
    },
    [CLEAR_COMMENTTITLES_ERRORS](context) {
        context.commit(REMOVE_COMMENTTITLES_ERRORS);
    },
    [COMMENTTITLES_SIMPLE_LIST](context, payload) {
        ApiService.setHeader();
        return new Promise(resolve => {
            ApiService.datatable("api/commenttitlessimple")
                .then(({data}) => {
                    if (data._metadata.outcomeCode === 0) {
                        resolve(data);
                    } else {
                        this.dispatch(SET_SNACK, {clr: 'error', msg: data._metadata.message});
                    }
                }).catch(({response}) => {
                context.commit(SET_COMMENTTITLES_ERROR, response.errors);
            });
        });
    },
};

const mutations = {
    [SET_COMMENTTITLES_ERROR](state, error) {
        state.errors = error;
    },
    [REMOVE_COMMENTTITLES_ERRORS](state) {
        state.errors = {};
    }
};

export default {
    state,
    actions,
    mutations
};
